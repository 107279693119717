import produce from 'immer'
import { SET_USERS, UPDATE_USER } from './userConstants'

export default produce((draft = {}, action) => {
    switch (action.type) {
        case SET_USERS:
            draft = action.users
            break
        case UPDATE_USER:
            draft[action.userId] = {
                ...draft[action.userId],
                ...action.userData,
            }
            break
        default:
            break
    }
    return draft
})
