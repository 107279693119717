import React, { Component } from 'react'
import _ from 'lodash'
import { withRouter } from 'react-router'
import {
    setToken,
    getMe,
    logout,
    refresh,
} from '../../features/auth/authActions'
import {setContext} from '../../features/entity/entityActions'
import { ENTITY_TARGET, reloadEntites } from '../../features/entity/entityActions'
import { connect } from 'react-redux'
import { Layout, notification } from 'antd'
import { ContentArea, Navbar } from '../components'
import { amplifyConfig } from '../../configs/AmplifyProd'
import BLPInstance from '../../BloombergService'
import { ERROR_COLOR } from '../constants'

const { Content, Footer  } = Layout


class MainAppContainer extends Component {
    componentWillUnmount
    constructor(props) {
        super(props)
        amplifyConfig()
    }
    componentDidMount() {
        // context source could be cms, bloomberg etc
        let src = window.bb ? 'bloomberg' : 'cms';
        this.isBloomberg = src === 'bloomberg';
        setContext({source: src});
        if (src === 'bloomberg') {
            this.props.setToken({authToken: null, refreshToken: null});
            let authCode = BLPInstance.getAuthorizationCode();

            if (authCode) {
                BLPInstance.bssoHandleOAuth2Callback(authCode).then(result => {
                    if (result && result['access_token']) {
                        this.props.setToken({authToken: result['access_token'], refreshToken: result['refresh_token']});
                    } 
                    else {
                        // BSSO error
                        notification.error({
                            duration: 0,
                            placement: 'topRight',
                            message: 'We have a problem accessing the Bloomberg services',
                            description: 'Please close the window and wait a few minutes before trying again',
                            style: {
                                backgroundColor: ERROR_COLOR,
                            },
                        })    
                    }
                });
            }
            else {
                BLPInstance.bssoOAuth2Signup().catch((err) => {
                    notification.error({
                        duration: 0,
                        placement: 'topRight',
                        message: 'We have a problem accessing the Bloomberg services',
                        description: 'Please close the window and wait a few minutes before trying again',
                        style: {
                            backgroundColor: ERROR_COLOR,
                        },
                    })    
                });
            }
        }

        if (
            !this.props.auth ||
            !this.props.auth.authToken ||
            !this.props.auth.refreshToken
        ) {
            // const { auth_token, refresh_token } = qs.parse(
            //     this.props.location.search
            // )
            // if (auth_token && refresh_token) {
            //     this.props.setToken({
            //         authToken: auth_token,
            //         refreshToken: refresh_token,
            //     })
            //     this.props.history.location.pathname = window.location.origin
            // } else if (!NO_AUTH_URLS.includes(window.location.pathname)) {
            //     window.location.href = window.location.origin
            // }
        } else {
            const { auth } = this.props
            const { authToken } = auth
            this.props.getMe(authToken)
        }

        // refresh JWT on clicks, no more than every 2.5 seconds
        window.addEventListener(
            'click',
            _.debounce(
                (e) => {
                    const { auth } = this.props
                    if (auth) {
                        const { refreshToken } = auth
                        if (refreshToken) {
                            this.props.refresh(refreshToken)
                        }
                    }
                },
                2500,
                { leading: true, trailing: false }
            )
        )
    }

    render() {
        const token = this.props.auth ? this.props.auth.authToken : null

        if (token && !this.props.auth.user) {
            this.props.getMe(token)
            if (this.props.entities && this.props.entities.all.length === 0) {
                // initialize something for the app on startup
                this.props.reloadEntites(this.props.entities.filters)
            }
        }

        return (
            <>
                <Layout className='layout' style={{ height: '100vh' }}>
                    {token ? (
                        <Navbar
                            user={this.props.auth.user}
                            token={token}
                            logout={this.props.logout}
                        />
                    ) : null}
                    <Content>
                        <div
                            style={{
                                background: '#fff',
                                padding: token ? 24 : 0,
                                maxHeight: token ? '80vh' : '100vh',
                                overflowY: 'scroll',
                                // height: 'calc(100vh - 100px)',
                            }}>
                            <ContentArea
                                token={token}
                                entities={this.props.entities}
                                user={this.props.auth.user}
                            />
                        </div>
                    </Content>
                    {token && <Footer style={{ textAlign: 'center', fontSize: '0.9em', color: 'gray', backgroundColor: '#FFF' }}>
                        {this.isBloomberg && 
                        <span>
                            All data is also available as a full or partial custom export via API or CSV. Please discuss with your SIP Client Engagement Manager - alex@schoolimprovementpartnership.net
                        </span>
                        }
                        {!this.isBloomberg && <span>
                            All data is also available as a full or partial custom export via API or CSV. Please discuss with your SIP Client Engagement Manager - <a href="mailto:alex@schoolimprovementpartnership.net">alex@schoolimprovementpartnership.net</a>
                        </span> }
                    </Footer>}
                </Layout>
            </>
        )
    }
}

const mapStateToProps = ({ auth, schools, entities }) => {
    return {
        auth,
        schools,
        entities,
    }
}

export default connect(mapStateToProps, {
    setContext,
    setToken,
    getMe,
    refresh,
    logout,
    reloadEntites,
})(withRouter(MainAppContainer))
