import { Auth } from 'aws-amplify'

class AmplifyService {
    userChangePassword = async (oldPass, newPass) => {
        return await Auth.currentAuthenticatedUser().then((user) => {
            return Auth.changePassword(user, oldPass, newPass)
        })
    }

    forgotPassword = async (username) => {
        return await Auth.forgotPassword(username)
    }

    resetPassword = async (username, code, newPass) => {
        return await Auth.forgotPasswordSubmit(username, code, newPass);
    }

    // Use lambda function to customize the email message
    // https://stackoverflow.com/questions/40610303/how-to-embed-a-personalized-parameter-into-amazon-cognito-verification-email
    // https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-lambda-custom-message.html
    sendVerificationCode = async (email) => {
        return await Auth.forgotPassword(email)
    }

    userSignUp = async (username, password) => {
        const user = await Auth.signUp({
            username,
            password,
            attributes: { email: username, name: username },
        })

        // await ApiService.postUserCredential(username, user.userSub);

        return user
    }

    userSignIn = async (username, password) => {
        try {
            const user = await Auth.signIn(username, password)
            return user
        } catch (e) {
            return e
        }
    }

    userSignout = async () => {
        const user = await Auth.signOut()
        return user
    }
}

export default new AmplifyService()
