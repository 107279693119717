import ReactGA from "react-ga4";
import { persistor, store } from './features/persistentStore';

const EVENT_TYPES = {
    registration: 'sign_up',  // GA default
    login: 'login',  // GA default
    logout: 'logout',
    modal: 'modal',
    analyze: "analyze",
    browse: 'browse',
    export: 'export',
    compare: 'compare',
    search: 'search',
    filter: 'filter',
    forgotPassword: 'ForgotPassword',
    resetPassword: 'ResetPassword',
}

class GAService {
    getEventContext = () => {
        let state = store.getState();
        return state && state.entities && state.entities.context ?  state.entities.context.source : 'cms';
    }

    customEvent = (action, label, value) => {
        let state = store.getState();
        // on production server, ignore SIP member activities on GA4
        if (['cms.schoolimprovementpartnership.com', 'cms.sip-webapp.com'].includes(window.location.hostname)
            && state && state.auth && state.auth.user && state.auth.user.email 
            && state.auth.user.email.indexOf('@schoolimprovementpartnership.net') >= 0) {
                return;
        }

        ReactGA.event({
            category: this.getEventContext(),
            action: action,
            label: label, // optional
            value: value, // optional, must be a number
            //nonInteraction: true, // optional, true/false
            //transport: "xhr", // optional, beacon/xhr/image
          });
    }

    registration = (success) => {
        this.customEvent(EVENT_TYPES.registration, success ? 1 : 0);
    }

    login = (success) => {
        this.customEvent(EVENT_TYPES.login, success ? 1 : 0);
    }

    logout = (success) => {
        this.customEvent(EVENT_TYPES.logout, success ? 1 : 0);
    }

    pageView = (page) => {
        ReactGA.send({hitType: "pageview", page: page});
    }

    openModal = (parent, name, data) => {
        this.customEvent(EVENT_TYPES.modal, `${parent} | ${name} | ${data}`);
    }

    analyze = (parent, type) => {
        this.customEvent(EVENT_TYPES.analyze, `${parent} | ${type}`);
    }

    compare = (parent, type) => {
        this.customEvent(EVENT_TYPES.compare, `${parent} | ${type}`);
    }

    search = (parent) => {
        this.customEvent(EVENT_TYPES.search, parent);
    }

    filter = (parent, type) => {
        this.customEvent(EVENT_TYPES.filter, `${parent} | ${type}`);
    }

    exportReport = (parent, type) => {
        this.customEvent(EVENT_TYPES.export, `${parent} | ${type}`);
    }

    forgotPassword = () => {
        this.customEvent(EVENT_TYPES.forgotPassword, 'verification code');
    }

    resetPassword = () => {
        this.customEvent(EVENT_TYPES.resetPassword, 'reset');
    }
}

export default new GAService()