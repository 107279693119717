import { SET_TOKEN, SET_USER, LOGOUT } from './authConstants'
import produce from 'immer'

const INITIAL_STATE = {}

export default produce((draft = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_TOKEN:
            const { authToken, refreshToken } = action
            draft['authToken'] = authToken
            draft['refreshToken'] = refreshToken
            break

        case SET_USER:
            const { user } = action
            draft['user'] = user
            break

        case LOGOUT:
            draft = INITIAL_STATE
            break

        default:
            break
    }

    return draft
})
