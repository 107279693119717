import React, { Component } from 'react'
import { MAILTO, ADMIN_EMAIL } from '../../shared/constants'
import decode from 'jwt-claims'

class Inactive extends Component {
    state = {
        identity: null,
        active: false,
    }

    componentDidMount() {
        if (this.props.token) {
            const { identity, user_claims } = decode(this.props.token)
            const { active } = user_claims
            this.setState({ identity, active })

            if (active) {
                // window.location.href = HOME_URL
            }
        }
    }

    render() {
        return (
            <>
                <div className='login-inactive'>
                    <div className='inactive-container'>
                        <div className='inactive-title'>Inactive User</div>
                        <p style={{ padding: 5, fontSize: '1.3em' }}>
                            You are an inactive user.
                        </p>
                        <p>Please email {<a href={MAILTO}>{ADMIN_EMAIL}</a>}</p>
                        <p style={{ marginTop: -5 }}>
                            for access to the application.
                        </p>
                    </div>
                </div>
            </>
        )
    }
}

export default Inactive
