import { Button, Switch, Table } from 'antd'
import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setModalState } from '../../../features/UI/UIActions'

const columns = (props) => [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text) => <div>{text}</div>,
    },
    {
        title: 'Active',
        dataIndex: 'active',
        key: 'active',
        render: (activeBool) => (
            <Switch disabled checked={activeBool} onChange={_.noop} />
        ),
    },
    {
        title: 'Full Subscription',
        dataIndex: 'full_subscription',
        key: 'full_subscription',
        render: (fullSubBool) => (
            <Switch disabled checked={fullSubBool} onChange={_.noop} />
        ),
    },
    {
        title: 'Edit',
        dataIndex: 'id',
        key: 'id',
        render: (id) => (
            <Button
                onClick={() =>
                    props.setModalState('group', {
                        visible: true,
                        initialValues: props.groups[id],
                    })
                }>
                Edit
            </Button>
        ),
    },
]
class Groups extends Component {
    state = {}
    render() {
        const groups = _.map(this.props.groups, (g) => g)
        if (groups) {
            groups.sort((a, b) => {
                if (a.name && b.name) {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                }
                if (a.name) {
                    return -1;
                }
                return 1;
            })
        }

        return (
            <div className='admin-content-section'>
                <div className='admin-actions-row'>
                    <Button
                        onClick={() => {
                            this.props.setModalState('group', {
                                visible: true,
                                newGroup: true,
                            })
                        }}
                        type='primary'
                        className='admin-actions-button'>
                        Add New Group
                    </Button>
                </div>
                <Table
                    className='admin-table'
                    columns={columns(this.props)}
                    dataSource={groups}
                />
            </div>
        )
    }
}

const mapStateToProps = ({ groups }) => {
    return {
        groups,
    }
}

export default connect(mapStateToProps, {
    setModalState,
})(withRouter(Groups))
