import { Button, Switch, Table, Tag, Input } from 'antd'
import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setModalState } from '../../../features/UI/UIActions'
import {
  resendConfirmationEmail,
  sendPasswordResetEmail,
  deleteUser
} from '../../../features/user/userActions'
import moment from 'moment'
import Highlighter from 'react-highlight-words';
import {
    SearchOutlined,
} from '@ant-design/icons'
import { MISSING_METADATA_STR } from '../../../shared/constants'

class Users extends Component {
    state = {
        showInactive: false,
        searchText: '',
        searchedColumn: '',
      };
    
      getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            text
          ),
      });
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
    
    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <div>{text}</div>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text) => <div>{text}</div>,
            ...this.getColumnSearchProps('email'),
        },
        {
            title: 'Groups',
            dataIndex: 'groups',
            key: 'groups',
            render: (groups) => {
                return _.map(groups, (g) => {
                    return <Tag key={g.id}>{g.name}</Tag>
                })
            },
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            render: (text) => {
                return <Tag>{moment(text).format('MMMM Do YYYY')}</Tag>
            },
        },
        {
            title: 'Updated',
            dataIndex: 'updated',
            key: 'updated',
            render: (text) => {
                return <Tag>{moment(text).format('MMMM Do YYYY')}</Tag>
            },
        },
        {
            title: 'Last Login',
            dataIndex: 'last_login',
            key: 'last_login',
            render: (text) => {
                return  <Tag>{text ? moment.utc(text).local().format('MMMM Do YYYY') : MISSING_METADATA_STR}</Tag>
            },
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            filters: [
                {
                  text: 'True',
                  value: true,
                },
                {
                  text: 'False',
                  value: false,
                },
            ],
            onFilter: (value, record) => {
                return record.active === value
            },
            render: (activeBool) => (
                <Switch disabled checked={activeBool} onChange={_.noop} />
            )
        },
        {
            title: 'Admin',
            dataIndex: 'admin',
            key: 'admin',
            render: (adminBool) => (
                <Switch disabled checked={adminBool} onChange={_.noop} />
            ),
        },
        {
            title: 'Edit',
            dataIndex: 'id',
            key: 'id',
            render: (id) => (
                <Button
                    onClick={() => {
                        this.props.setModalState('users', {
                            visible: true,
                            initialValues: this.props.users[id],
                        })
                    }}>
                    Edit
                </Button>
            ),
        },

        {
          title: 'Delete',
          dataIndex: 'id',
          key: 'id',
          render: (id) => (
              <Button
                  onClick={() => {
                      this.props.deleteUser(this.props.token, id, this.props.users);                  
                  }}>
                  Delete
              </Button>
          ),
        },

        {
          title: 'Resend',
          dataIndex: 'id',
          key: 'id',
          render: (id) => (
              <Button
                  onClick={() => {
                    this.props.resendConfirmationEmail(this.props.users[id].email, this.props.token);
                  }}>
                  Resend Confirmation Email
              </Button>
          ),
        },

        {
          title: 'Reset Password',
          dataIndex: 'id',
          key: 'id',
          render: (id) => (
              <Button
                  onClick={() => {                    
                    this.props.sendPasswordResetEmail(this.props.users[id].email, this.props.token);
                  }}>
                  Reset Password
              </Button>
          ),
      },
    ]

    handleAddUser = () => {
        this.props.setModalState('users', {
            visible: true,
            initialValues: null,
            newUser: true,
        })
    }

    render() {
        const users = _.map(this.props.users, (u) => u)
        if (users) {
              users.sort((a, b) => {
              if (a.name && b.name) {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
              }
              if (a.name) {
                return -1;
              }
              return 1;
            })
        }

        return (
            <div className='admin-content-section'>
                <div className='admin-actions-row'>
                    <Button
                        onClick={this.handleAddUser}
                        type='primary'
                        className='admin-actions-button'>
                        Add New User
                    </Button>
                </div>
                <Table
                    className='admin-table'
                    columns={this.columns}
                    rowKey='id'
                    dataSource={users}
                    scroll={{ x: 240 }}
                />
            </div>
        )
    }
}

const mapStateToProps = ({ users, groups }) => {
    return {
        users,
        groups,
    }
}

export default connect(mapStateToProps, {
    resendConfirmationEmail,
    sendPasswordResetEmail,
    deleteUser,
    setModalState,
})(withRouter(Users))
