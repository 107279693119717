import React from 'react'
import {
    Row,
    Col,
    Card,
    Tooltip,
    Button,
    Comment,
    List,
    Input,
    Form,
    notification,
    Space,
    Typography,
} from 'antd'
import { Column, Area } from '@ant-design/charts'
import { MISSING_METADATA_STR, ERROR_COLOR } from '../../shared/constants'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import Modal from 'antd/lib/modal/Modal'
import BLPInstance from '../../BloombergService'
import { InfoCircleFilled } from '@ant-design/icons'

const SIP_GRAPH_GREEN = '#88b725'
const SIP_GRAPH_BLUE = null

const { Meta } = Card
const { TextArea } = Input
const { Title } = Typography

function checkValue(rawValue, finalString) {
    if (rawValue == null || (!rawValue && rawValue !== 0)) {
        return MISSING_METADATA_STR
    } else {
        return finalString
    }
}

function CardBox(props) {
    return (
        <>
            <div style={{ paddingTop: 20 }}>
                <Card
                    className='analyze-card'
                    style={{
                        paddingTop: 25,
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        height: 150
                    }}>
                    <Meta
                        className='analyze-meta'
                        title={props.title}
                        description={props.description}
                    />
                </Card>
            </div>
        </>
    )
}

function SubtitleEntry(props) {
    return (
        <div
            style={{
                height: props.heightSize,
            }}>
            <div
                style={{
                    paddingTop: 30,
                    justifyContent: 'center',
                    alignContent: 'space-between',
                    fontSize: 28,
                    fontWeight: 'bold',
                    alignItems: 'flex-center',
                    display: 'flex',
                    textAlign: 'center',
                    lineHeight: 1.25,
                }}>
                {props.value ? props.value : MISSING_METADATA_STR}
            </div>
            <div
                style={{
                    justifyContent: 'center',
                    alignContent: 'space-between',
                    fontSize: 15,
                    alignItems: 'flex-center',
                    display: 'flex',
                    textAlign: 'center',
                }}>
                {props.name}
            </div>
        </div>
    )
}

function AnalyzeTitle(props) {
    return (
        <Row
            justify='center'
            style={{
                height: 150,
            }}>
            <Col
                style={{
                    backgroundColor: '#88b725',
                    color: 'white',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    fontWeight: 'bold',
                    fontSize: 35,
                }}
                span={18}>
                {props.name}, {props.state}
            </Col>
            <Col
                span={1}
                style={{
                    backgroundColor: '#1c467c',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}>
                {/* Custom CSS For Triangle pointing right */}
                <div
                    style={{
                        width: 0,
                        height: 0,
                        borderTop: '28px solid transparent',
                        borderLeft: '28px solid #88b725',
                        borderBottom: '28px solid transparent',
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                />
            </Col>
            <Col
                span={5}
                style={{
                    backgroundColor: '#1c467c',
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    justifyContent: 'center',
                    paddingRight: 25,
                }}>
                <div
                    style={{
                        backgroundColor: '#1c467c',
                        color: '#88b725',
                        justifyContent: 'center',
                        alignContent: 'center',
                        display: 'flex',
                        height: 75,
                        fontWeight: 800,
                        alignItems: 'flex-end',
                        fontSize: 23,
                    }}>
                    {props.year}
                </div>
                <div
                    style={{
                        backgroundColor: '#1c467c',
                        color: 'white',
                        justifyContent: 'center',
                        alignContent: 'center',
                        display: 'flex',
                        height: 75,
                        fontSize: 20,
                        alignItems: 'flex-start',
                    }}>
                    {props.yearText}
                </div>
            </Col>
        </Row>
    )
}

function SubtitleEntryFull(props) {
    return (
        <SubtitleEntry name={props.name} value={props.value} heightSize={300} />
    )
}

function SubtitleEntryHalf(props) {
    return (
        <SubtitleEntry name={props.name} value={props.value} heightSize={150} />
    )
}

function SubtitleEntryThird(props) {
    return (
        <SubtitleEntry name={props.name} value={props.value} heightSize={100} />
    )
}

// Need to wrap return in divs unless you want to break your browser
function AreaGraph(props) {
    const { data } = props
    const { xField } = props
    const { yField } = props
    const { title } = props
    const { height } = props
    const { color } = props
    const { yAxisFormatter } = props
    const dataOrdered = _.sortBy(data, xField)

    const config = {
        data: dataOrdered,
        height: height,
        xField: xField,
        yField: yField,
        color,
        point: {
            size: 5,
            shape: 'diamond',
        },
        yAxis: {
            label: {
                formatter: yAxisFormatter,
            },
        },
    }
    return (
        <div style={{ paddingTop: 12, paddingLeft: 10, paddingRight: 10 }}>
            <h2>{title}</h2>
            <Area {...config} />
        </div>
    )
}

function AnalyzeHeader(props) {
    return (
        <>
            <div>
                <Row
                    style={{
                        height: 60,
                    }}>
                    <Col
                        style={{
                            backgroundColor: '#1c467c',
                            color: '#88b725',
                            justifyContent: 'center',
                            alignContent: 'center',
                            display: 'flex',
                            fontSize: 30,
                            fontWeight: 'bold',
                            paddingTop: 5,
                        }}
                        span={24}>
                        {props.name}
                    </Col>
                </Row>
            </div>
            {/*Custom CSS for downard pointing triangle */}
            <div
                style={{
                    width: 0,
                    height: 0,
                    borderLeft: '15px solid transparent',
                    borderRight: '15px solid transparent',
                    borderTop: '15px solid #1c467c',
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    paddingBottom: 25,
                }}
            />
        </>
    )
}

// Need to wrap return in divs unless you want to break your browser
function ColumnGraph(props) {
    const { title } = props
    const { data } = props
    const { color } = props
    const { height } = props
    const config = {
        height,
        data,
        color,
        xField: 'type',
        yField: 'value',
        yAxis: {
            label: {
                formatter: function formatter(v) {
                    return `$${v.toLocaleString()}`
                },
            },
        },
    }
    return (
        <div>
            <h1>{title}</h1>
            <Column {...config} />
        </div>
    )
}

function FinacialBoxes(props) {
    const { data } = props
    return (
        <>
            <Col span={4}>
                <CardBox
                    title={getDollarFormattedString(data.total_revenue)}
                    description='Total Revenue'
                />
                <CardBox
                    title={
                        data.ebida_margin
                            ? checkValue(
                                  data.ebida_margin,
                                  `${(data.ebida_margin * 100).toFixed(1)} %`
                              )
                            : MISSING_METADATA_STR
                    }
                    description='EBIDA Margin'
                />
                <CardBox
                    title={checkValue(
                        data.total_assets_deferred_outflows,
                        getDollarFormattedString(
                            data.total_assets_deferred_outflows
                        )
                    )}
                    description='Total Assets'
                />
                <CardBox
                    title={checkValue(
                        data.unrestricted_cash_and_investments,
                        getDollarFormattedString(
                            data.unrestricted_cash_and_investments
                        )
                    )}
                    description='Cash + Inv. + Bd. Desig.'
                />
            </Col>
            <Col span={4}>
                <CardBox
                    title={checkValue(
                        data.total_expense,
                        getDollarFormattedString(data.total_expense)
                    )}
                    description='Total Expense'
                />
                <CardBox
                    title={checkValue(
                        data.net_income_margin_percentage,
                        data.net_income_margin_percentage ? `${(data.net_income_margin_percentage * 100).toFixed(
                            1
                        )} %` : MISSING_METADATA_STR
                    )}
                    description='Net Income Margin'
                />
                <CardBox
                    title={checkValue(
                        data.total_liabilities_and_deferred_inflows,
                        getDollarFormattedString(
                            data.total_liabilities_and_deferred_inflows
                        )
                    )}
                    description='Total Liabilities'
                />
                <CardBox
                    title={checkValue(
                        data.unrestricted_cash_investments_to_debt,
                        data.unrestricted_cash_investments_to_debt ? `${data.unrestricted_cash_investments_to_debt.toFixed(
                            1
                        )} %` : MISSING_METADATA_STR
                    )}
                    description='Cash + Bd. Desig. + Inv. to debt'
                />
            </Col>
            <Col span={4}>
                <CardBox
                    title={data.ebida ? checkValue(
                        data.ebida,
                        getDollarFormattedString(data.ebida)
                    ) : MISSING_METADATA_STR}
                    description='EBIDA'
                />
                <CardBox
                    title={data.annual_debt_service_coverage ? checkValue(
                        data.annual_debt_service_coverage,
                        `${data.annual_debt_service_coverage.toFixed(2)} x`
                    ) : MISSING_METADATA_STR}
                    description='Annual Debt Service Coverage'
                />
                <CardBox
                    title={data.unrestricted_net_position ? checkValue(
                        data.unrestricted_net_position,
                        getDollarFormattedString(data.unrestricted_net_position)
                    ) : MISSING_METADATA_STR}
                    description='Unrestricted Net Position'
                />
                <CardBox
                    title={data.days_cash_on_hand ? checkValue(
                        data.days_cash_on_hand,
                        data.days_cash_on_hand.toFixed(1)
                    ) : MISSING_METADATA_STR}
                    description='Days Cash On Hand'
                />
            </Col>
            <Col span={4}>
                <CardBox
                    title={data.net_income ? checkValue(
                        data.net_income,
                        getDollarFormattedString(data.net_income)
                    ) : MISSING_METADATA_STR}
                    description='Net Income'
                />
                <CardBox
                    title={data.dsc_covenant ? checkValue(
                        data.dsc_covenant,
                        `${data.dsc_covenant.toFixed(2)} x`
                    ) : MISSING_METADATA_STR}
                    description='DSC Covenant'
                />
                <CardBox
                    title={data.total_debt ? checkValue(
                        data.total_debt,
                        getDollarFormattedString(data.total_debt)
                    ) : MISSING_METADATA_STR}
                    description='Total Debt'
                />
                <CardBox
                    title={data.days_cash_covenant ? checkValue(
                        data.days_cash_covenant,
                        data.days_cash_covenant >= 1
                            ? data.days_cash_covenant.toFixed(1)
                            : `${(data.days_cash_covenant * 100).toFixed(
                                  1
                              )} % of debt`
                    ) : MISSING_METADATA_STR}
                    description='Days Cash Covenant'
                />
            </Col>
        </>
    )
}

const CommentAddForm = ({ onChange, onSubmit, submitting, value }) => (
    <>
        <Form.Item>
            <TextArea rows={3} onChange={onChange} value={value} />
        </Form.Item>
        <Form.Item>
            <Button
                style={{ float: 'right' }}
                htmlType='submit'
                loading={submitting}
                onClick={onSubmit}
                type='primary'>
                Add New Comment
            </Button>
        </Form.Item>
    </>
)

class SIPComment extends React.Component {
    state = {
        isEditing: false,
        submitting: false,
        currCommentValue: '',
    }

    constructor(props) {
        super(props)
        this.handleRemove = props.handleRemove
        this.comment = props.comment
        this.isAdmin = props.isAdmin
        this.handleEdit = props.handleEdit
        this.id = props.id
        this.state = {
            ...this.state,
            currCommentValue: this.comment,
        }
    }

    getValue() {
        return this.state.currCommentValue
    }

    onEditChange = (e) => {
        this.setState({
            ...this.state,
            currCommentValue: e.target.value,
        })
    }

    onEditSubmit = () => {
        this.setState({
            ...this.state,
            submitting: true,
        })
        this.handleEdit(this.id, this.getValue())
        this.setState({
            ...this.state,
            submitting: false,
            isEditing: false,
        })
    }

    render() {
        const actions = this.isAdmin
            ? [
                  <span
                      key='comment-edit'
                      onClick={() => {
                          this.setState({
                              ...this.state,
                              isEditing: true,
                          })
                      }}>
                      Edit
                  </span>,
                  <span
                      key='comment-remove'
                      onClick={() => {
                          this.handleRemove(this.id)
                      }}>
                      Remove
                  </span>,
              ]
            : []
        if (this.state.isEditing) {
            return (
                <>
                    <Form.Item>
                        <TextArea
                            rows={1}
                            onChange={this.onEditChange}
                            value={this.getValue()}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        isEditing: false,
                                        currCommentValue: this.comment,
                                    })
                                }}>
                                Cancel
                            </Button>
                            <Button
                                htmlType='submit'
                                loading={this.submitting}
                                onClick={this.onEditSubmit}
                                type='primary'>
                                Save
                            </Button>
                        </Space>
                    </Form.Item>
                </>
            )
        }
        return <Comment actions={actions} content={this.getValue()} />
    }
}

function EntityComments(props) {
    const { handleSubmit } = props
    const { handleChange } = props
    const { getComments } = props
    const { newCommentValue } = props
    const { handleRemove } = props
    const { handleEdit } = props
    const { isAdmin } = props

    const comments = getComments()
    const header =
        comments.length === 1 ? '1 Comment' : `${comments.length} Comments`

    return (
        <>
            <List
                header={header}
                itemLayout='horizontal'
                dataSource={comments}
                locale={{
                    emptyText:
                        'There are currently no comments for this entity.',
                }}
                renderItem={(item) => (
                    <li>
                        <SIPComment
                            handleRemove={handleRemove}
                            handleEdit={handleEdit}
                            id={item.id}
                            comment={item.comment}
                            isAdmin={isAdmin}
                        />
                    </li>
                )}
            />
            {isAdmin ? (
                <Comment
                    content={
                        <CommentAddForm
                            onSubmit={handleSubmit}
                            onChange={handleChange}
                            value={newCommentValue}
                        />
                    }></Comment>
            ) : (
                <></>
            )}
        </>
    )
}

class AnalyzeComments extends React.Component {
    state = {
        comments: [],
        submitting: false,
        newCommentValue: '',
    }

    constructor(props) {
        super(props)
        this.comments = props.comments
        this.name = props.name
        this.apiAddComment = props.apiAddComment
        this.apiEditComment = props.apiEditComment
        this.token = props.token
        this.apiRemoveComment = props.apiRemoveComment
        this.entityID = props.entityID
        this.user = props.user
        this.state = {
            ...this.state,
            comments: this.comments,
        }
    }

    showModal = () => {
        this.setState({
            ...this.state,
            visible: true,
        })
    }

    handleCancel = () => {
        this.setState({
            ...this.state,
            visible: false,
        })
    }

    handleEdit = (id, newComment) => {
        this.setState({
            ...this.state,
            submitting: true,
        })
        this.apiEditComment(this.token, newComment, id).then((resp) => {
            this.setState({
                ...this.state,
                submitting: false,
                comments: this.state.comments.map((c) =>
                    c.id === id ? { id: c.id, comment: newComment } : c
                ),
            })
        })
    }

    handleSubmit = () => {
        this.setState({
            ...this.state,
            submitting: true,
        })
        const newComment = this.state.newCommentValue
        this.apiAddComment(this.token, newComment, this.entityID).then(
            (resp) => {
                const newID = !resp ? 0 : !resp.data ? 0 : resp.data.id
                if (!newID) {
                    notification.error({
                        duration: 0,
                        placement: 'topRight',
                        message: 'Unable to create new comment',
                        description: 'Please contact system adminstrator.',
                        style: { backgroundColor: ERROR_COLOR },
                    })
                }
                this.setState({
                    ...this.state,
                    submitting: false,
                    newCommentValue: '',
                    comments: [
                        ...this.state.comments,
                        {
                            comment: newComment,
                            id: newID,
                        },
                    ],
                })
            }
        )
    }

    handleRemove = (id) => {
        this.setState({
            ...this.state,
            submitting: true,
        })
        this.apiRemoveComment(this.token, id).then((resp) => {
            _.remove(this.state.comments, (c) => c.id === id)
            this.setState({
                ...this.state,
                submitting: false,
                comments: this.state.comments,
            })
        })
    }

    handleChange = (e) => {
        this.setState({
            ...this.state,
            newCommentValue: e.target.value,
        })
    }

    getComments = () => {
        return this.state.comments
    }

    isVisible() {
        return this.state.visible
    }

    render() {
        const numComments = this.getComments().length
        return (
            <>
                <Tooltip title='Click to expand Comments'>
                    <div
                        style={{
                            height: 150,
                        }}>
                        <div
                            onClick={this.showModal}
                            style={{
                                paddingTop: 30,
                                justifyContent: 'center',
                                alignContent: 'space-between',
                                fontSize: 28,
                                fontWeight: 'bold',
                                alignItems: 'flex-center',
                                display: 'flex',
                                textAlign: 'center',
                                lineHeight: 1.25,
                            }}>
                            {`${numComments}`}
                        </div>
                        <div
                            style={{
                                justifyContent: 'center',
                                alignContent: 'space-between',
                                fontSize: 15,
                                alignItems: 'flex-center',
                                display: 'flex',
                                textAlign: 'center',
                            }}>
                            <div
                                className='analyze-modal-button'
                                onClick={this.showModal}>
                                {numComments === 1 ? 'COMMENT' : 'COMMENTS'}
                            </div>
                        </div>
                    </div>
                </Tooltip>
                <Modal
                    className='details-modal'
                    title={`Comments - ${this.name}`}
                    visible={this.isVisible()}
                    footer={null}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}>
                    {
                        <EntityComments
                            handleRemove={this.handleRemove}
                            handleSubmit={this.handleSubmit}
                            handleChange={this.handleChange}
                            handleEdit={this.handleEdit}
                            getComments={this.getComments}
                            newCommentValue={this.state.newCommentValue}
                            isAdmin={
                                this.user &&
                                (this.user.admin || this.user.is_admin)
                            }
                        />
                    }
                </Modal>
            </>
        )
    }
}

function formatAddress(contact) {
    return contact.street_address
        ? `${contact.street_address}, ${contact.city}, ${contact.state}, ${contact.zip}`
        : MISSING_METADATA_STR
}

function ContactItem(props) {
    const { name } = props
    const { value } = props
    return (
        <p>
            <b>{name}: </b>
            {value ? value : MISSING_METADATA_STR}
        </p>
    )
}

function EntityContact(props) {
    const { contact } = props
    return (
        <>
            <ContactItem name={'Name'} value={contact.contact_name} />
            <ContactItem name={'Email'} value={contact.email_address} />
            <ContactItem name={'Phone'} value={contact.phone} />
            <ContactItem name={'Fax'} value={contact.fax} />
            <ContactItem name={'Address'} value={formatAddress(contact)} />
        </>
    )
}

function EntityContacts(props) {
    const { getContacts } = props

    const contacts = getContacts()
    const header =
        contacts.length === 1 ? '1 Contact' : `${contacts.length} Contacts`

    return (
        <>
            <List
                header={header}
                itemLayout='horizontal'
                dataSource={contacts}
                locale={{
                    emptyText: 'There are no contacts for this entity.',
                }}
                renderItem={(item) => (
                    <li>
                        <EntityContact contact={item} />
                    </li>
                )}
            />
        </>
    )
}

// Currently Only used by School - will need to fix scaling if CMOs add contacts
class AnalyzeContacts extends React.Component {
    state = {
        contacts: [],
    }

    constructor(props) {
        super(props)
        this.contacts = props.contacts
        this.name = props.name
        this.entityID = props.entityID
        this.state = {
            ...this.state,
            contacts: this.contacts,
        }
    }

    showModal = () => {
        this.setState({
            ...this.state,
            visible: true,
        })
    }

    getContacts = () => {
        return this.state.contacts
    }

    isVisible() {
        return this.state.visible
    }

    handleCancel = () => {
        this.setState({
            ...this.state,
            visible: false,
        })
    }

    render() {
        const numContacts = this.getContacts().length
        return (
            <>
                <Tooltip title='Click to expand Contacts'>
                    <div
                        style={{
                            height: 100,
                        }}>
                        <div
                            onClick={this.showModal}
                            style={{
                                paddingTop: 30,
                                justifyContent: 'center',
                                alignContent: 'space-between',
                                fontSize: 28,
                                fontWeight: 'bold',
                                alignItems: 'flex-center',
                                display: 'flex',
                                textAlign: 'center',
                                lineHeight: 1.25,
                            }}>
                            {`${numContacts}`}
                        </div>
                        <div
                            style={{
                                justifyContent: 'center',
                                alignContent: 'space-between',
                                fontSize: 15,
                                alignItems: 'flex-center',
                                display: 'flex',
                                textAlign: 'center',
                            }}>
                            <div
                                className='analyze-modal-button'
                                onClick={this.showModal}>
                                {numContacts === 1 ? 'CONTACT' : 'CONTACTS'}
                            </div>
                        </div>
                    </div>
                </Tooltip>
                <Modal
                    className='details-modal'
                    title={`Contacts - ${this.name}`}
                    visible={this.isVisible()}
                    footer={null}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}>
                    {<EntityContacts getContacts={this.getContacts} />}
                </Modal>
            </>
        )
    }
}

function UnauditedInfo(props) {
    const { financials } = props
    const {
        local_funding,
        state_funding,
        federal_funding,
        other_revenue,
        total_revenue,
        instructional_expense,
        systemwide_support_expense,
        interest_expense,
        other_expense,
        total_expense,
        restatements_other_net_asset_changes,
        // purchase_plant_equipment,
        unrestricted_net_position,
        total_net_position,
        change_net_position,
        financials_source,
        other_schools,
    } = financials
    console.log(other_schools)
    return (
        <>
            <Title level={2}>Note:</Title>
            <b>
                {`This information is based on unaudited financial data provided to the state and has not been verified by School Improvment Partnership.`}
                <br />
                {`Click`}
                {
                    <Link to={{ pathname: financials_source }} target='_blank'>
                        {' '}
                        <u>here</u>
                    </Link>
                }{' '}
                {`for more information about the state's data requirements and standards.`}
            </b>
            <br />
            <br />
            <Title level={3}>Schools Included in this Information:</Title>
            <ul>
                {other_schools.map((item, i) => {
                    return <li key={i}>{item.name}</li>
                })}
            </ul>

            <Title level={2}>Statement of Activities</Title>
            <Title level={5}>Revenue:</Title>
            <Row gutter={8}>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(local_funding)}
                        description={'Local Sources'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(state_funding)}
                        description={'State Sources'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(federal_funding)}
                        description={'Federal Sources'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(other_revenue)}
                        description={'Other Revenue'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(total_revenue)}
                        description={'Total Revenue'}
                    />
                </Col>
            </Row>
            <br />
            <Title level={5}>Expenses:</Title>
            <Row gutter={8}>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(instructional_expense)}
                        description={'Instructional'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(
                            systemwide_support_expense
                        )}
                        description={'Support'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(interest_expense)}
                        description={'Interest'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(null)}
                        description={'Depreciation & Amorization'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(other_expense)}
                        description={'Other Expense'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(total_expense)}
                        description={'Total Expense'}
                    />
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(
                            restatements_other_net_asset_changes
                        )}
                        description={'Other Net Asset Changes'}
                    />
                </Col>
            </Row>
            <br />
            <Title level={2}>Net Position</Title>
            <Row gutter={8}>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(change_net_position)}
                        description={'Change in Net Position'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(
                            unrestricted_net_position
                        )}
                        description={'Unrestricted Net Position'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(total_net_position)}
                        description={'Total Net Position'}
                    />
                </Col>
            </Row>
            <br />
            <Title level={2}>Balance Sheet</Title>
            <Row gutter={8}>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(null)}
                        description={'Cash & Investments'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(null)}
                        description={'Net Capital Assets'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(null)}
                        description={'Total Assets'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(null)}
                        description={'Total Debt'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(null)}
                        description={'Pension Liability'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(null)}
                        description={'OPEB Liability'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(null)}
                        description={'Total Liabilities'}
                    />
                </Col>
            </Row>
            <br />
            <Title level={2}>Other Metrics</Title>
            <Row gutter={8}>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(null)}
                        description={'Purchase of Capital Assets'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(null)}
                        description={'Principal Payments of Debt'}
                    />
                </Col>
            </Row>
            <br />
            <Title level={2}>Ratios</Title>
            <Row gutter={8}>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(null)}
                        description={'Net Income'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={MISSING_METADATA_STR}
                        description={'Net Income Margin (%)'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(null)}
                        description={'EBIDA'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={MISSING_METADATA_STR}
                        description={'EBIDA Margin (%)'}
                    />
                </Col>
                <Col span={4}>
                    <CardBox
                        title={getDollarFormattedString(null)}
                        description={'Days Cash On Hand'}
                    />
                </Col>
            </Row>
            <br />
        </>
    )
}

class UnauditedFinancials extends React.Component {
    state = {
        financials: {},
    }

    constructor(props) {
        super(props)
        this.financials = props.financials
        this.name = props.name
        this.entityID = props.entityID
        this.state = {
            ...this.state,
            financials: this.financials,
        }
    }

    showModal = () => {
        this.setState({
            ...this.state,
            visible: true,
        })
    }

    getFinancials = () => {
        return this.state.financials
    }

    isVisible() {
        return this.state.visible
    }

    handleCancel = () => {
        this.setState({
            ...this.state,
            visible: false,
        })
    }

    render() {
        return !this.financials ? (
            <></>
        ) : (
            <>
                <div onClick={this.showModal}>
                    <AnalyzeHeader
                        name={
                            <Tooltip title='Click to expand data'>
                                <div className='financial-highlights-link'>
                                    {
                                        'Supplemental (Unaudited) Financial Information'
                                    }
                                </div>
                            </Tooltip>
                        }
                    />
                </div>
                <Modal
                    className='details-modal'
                    title={`Unaudited Financial Data as of ${this.financials.year} ($000s) - ${this.name}`}
                    visible={this.isVisible()}
                    okText='Close'
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    okButtonProps={{ type: 'normal' }}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    destroyOnClose={true}
                    width={1200}>
                    <UnauditedInfo financials={this.financials} />
                </Modal>
            </>
        )
    }
}

function FinancialHighlightsHeader(props) {
    const { url } = props
    const { year } = props
    const { fiscalEnd } = props
    const { context } = props
    const { auditSource } = props

    return (
        <>
            <Row
                style={{
                    height: 50,
                }}>
                <Col
                    style={{
                        backgroundColor: '#1c467c',
                        color: '#88b725',
                        justifyContent: 'center',
                        alignContent: 'center',
                        display: 'flex',
                        fontSize: 30,
                        fontWeight: 'bold',
                        paddingTop: 5,
                    }}
                    span={24}>
                    {BLPInstance.isBloomberg(context) && 
                    <div style={{color: '#88b725'}}>
                        {'FINANCIAL HIGHLIGHTS ($000s)'}
                    </div>}
                    
                    {!BLPInstance.isBloomberg(context) && 
                    <><div class="ant-row">
                    <Link
                        className='financial-highlights-link'
                        to={{ pathname: url }}
                        target='_blank'>
                        {'FINANCIAL HIGHLIGHTS ($000s)'}
                    </Link>
                    <Tooltip title="Click to view the detailed financial report">&nbsp;<InfoCircleFilled style={{position: 'relative', top: '-10px', color: 'white', fontSize: '1.1rem'}} /></Tooltip>
                    </div>
                    </>}
                </Col>
            </Row>
            <Row
                style={{
                    height: 35,
                }}>
                <Col
                    style={{
                        backgroundColor: '#1c467c',
                        color: 'white',
                        justifyContent: 'center',
                        alignContent: 'center',
                        display: 'flex',
                        fontSize: 20,
                        fontWeight: 'bold',
                    }}
                    span={24}>
                    {`Audited Entity: ${auditSource}`} - {fiscalEnd}, {year}
                </Col>
            </Row>
            {/*Custom CSS for downard pointing triangle */}
            <div
                style={{
                    width: 0,
                    height: 0,
                    borderLeft: '15px solid transparent',
                    borderRight: '15px solid transparent',
                    borderTop: '15px solid #1c467c',
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    paddingBottom: 25,
                }}
            />
        </>
    )
}

function FinancialHighlightsBody(props) {
    const { data } = props
    const { historical } = props
    return (
        <>
            <Row gutter={16}>
                <FinacialBoxes data={data} />
                <Col span={8}>
                    <ColumnGraph
                        data={historical.map((v) => {
                            return { type: v.year, value: v.total_revenue }
                        })}
                        title={'Total Revenue'}
                        height={400}
                        color={SIP_GRAPH_GREEN}
                    />
                </Col>
            </Row>
            <Row gutter={16} style={{ height: 400 }}>
                <Col span={12} style={{ paddingTop: 20 }}>
                    <ColumnGraph
                        title='EBIDA'
                        height={250}
                        color={SIP_GRAPH_GREEN}
                        data={historical.map((v) => {
                            return { type: v.year, value: v.ebida }
                        })}
                    />
                </Col>
                <Col span={12} style={{ paddingTop: 20 }}>
                    <ColumnGraph
                        title='Unrestricted Cash / Board Designated / Investments'
                        height={250}
                        data={historical.map((v) => {
                            return {
                                type: v.year,
                                value: v.unrestricted_cash_and_investments,
                            }
                        })}
                    />
                </Col>
            </Row>
            <div style={{ height: 20 }} />
        </>
    )
}

function FinancialHighlights(props) {
    const { data, context } = props
    return data.info.debt_issuer ? (
        <>
            <FinancialHighlightsHeader
                year={data.financial_data.year}
                fiscalEnd={data.financial_data.fiscal_end}
                url={data.financial_data.emma_url}
                context={context}
                auditSource={data.financial_data ? data.financial_data.audit_source : 'N/A'}
            />
            <FinancialHighlightsBody
                data={data.financial_data}
                historical={data.historical_financials}
            />
        </>
    ) : (
        <> </>
    )
}

function getDollarFormattedString(num) {
    if (num) {
        return num >= 0
            ? `$${num.toLocaleString()}`
            : `-$${Math.abs(num).toLocaleString()}`
    }
    return MISSING_METADATA_STR
}

function getWebsiteLink(website, context) {
    return website ? (
        BLPInstance.isBloomberg(context) ? website : (
        <Link
            to={{
                pathname: website.startsWith('http')
                    ? website
                    : `https://${website}`,
            }}
            target='_blank'>
            {website ? website : MISSING_METADATA_STR}
        </Link>)
    ) : (
        MISSING_METADATA_STR
    )
}

export {
    CardBox,
    AnalyzeTitle,
    AnalyzeComments,
    AnalyzeContacts,
    SubtitleEntry,
    SubtitleEntryFull,
    SubtitleEntryHalf,
    SubtitleEntryThird,
    AreaGraph,
    AnalyzeHeader,
    ColumnGraph,
    FinancialHighlightsHeader,
    FinancialHighlights,
    FinacialBoxes,
    UnauditedFinancials,
    SIP_GRAPH_GREEN,
    SIP_GRAPH_BLUE,
    getDollarFormattedString,
    getWebsiteLink,
}
