import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Button, Row, Col, Menu, Dropdown } from 'antd'
import { BACKEND_URL } from '../../shared/constants'
import {
    LogoutOutlined,
    LineChartOutlined,
    HomeOutlined,
    SwapOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons'
import Logo from '../../assets/sip-logo-white.png'
import { HOME_URL, ANALYZE_URL, COMPARE_URL } from '../constants'
import { connect } from 'react-redux'
import './NavBar.scss'
import GAService from '../../GAService'
import BLPInstance from '../../BloombergService'

class Navbar extends Component {
    state = {
        current: HOME_URL,
    }

    getHelpButton() {
        const EMAIL = 'email'
        const GUIDE = 'guide'
        const STANDARDS = 'standards'
        const SUGGESTIONS = 'suggestions'
        const onClick = ({ key }) => {
            if (key === EMAIL) {
                window.location =
                    'mailto:cms@schoolimprovementpartnership.net?subject=Support%20for%20SIP%20Database'
            } else if (key === GUIDE) {
                window.open(
                    `${BACKEND_URL}/support/guide?token=${this.props.token}`,
                    '_blank'
                )
            } else if (key === STANDARDS) {
                window.open(
                    `${BACKEND_URL}/support/data_standards?token=${this.props.token}`,
                    '_blank'
                )
            }
            else if (key === SUGGESTIONS) {
                window.open(
                    'https://docs.google.com/forms/d/1_SaXj6GXmG0pTMaIlbTQVoBpInOA74fpon0CjOBmSRk/edit',
                    '_blank'
                )
            }
        }
        const menu = (
            <Menu onClick={onClick}>
                <Menu.Item key={EMAIL}>Contact Us</Menu.Item>
                <Menu.Item key={GUIDE}>User Guide</Menu.Item>
                <Menu.Item key={STANDARDS}>Data Standards</Menu.Item>
                <Menu.Item key={SUGGESTIONS}>Support and Suggestion</Menu.Item>
            </Menu>
        )
        return (
            <div style={{ marginLeft: 10 }}>
                <Dropdown overlay={menu} placement='bottomCenter'>
                    <Button size='small'>
                        Help <QuestionCircleOutlined />
                    </Button>
                </Dropdown>
            </div>
        )
    }

    componentDidUpdate() {
        const { current } = this.state
        const { pathname } = window.location
        if (current !== pathname) {
            this.setState({ current: pathname })
        }
    }
    render() {
        const { current } = this.state
        return (
            <>
                <Row className="top-bar" align="middle">
                    <Col xs={6} sm={4}>
                    <img className='nav-logo' alt='sip-logo' src={Logo} />
                    </Col>
                    <Col xs={18} sm={{span: BLPInstance.isBloomberg(this.props) ? 9: 8, offset: BLPInstance.isBloomberg(this.props) ? 1 : 0}}>

                        <Menu
                            className='top-menu'
                            mode='horizontal'
                            selectedKeys={[current]}
                            style={{
                                backgroundColor: '#1c467c',
                                color: 'white',
                            }}>
                            <Menu.Item key={HOME_URL}>
                                <Link to={HOME_URL}>
                                    {<HomeOutlined />} Home
                                </Link>
                            </Menu.Item>
                            <Menu.Item key={ANALYZE_URL}>
                                <Link to={ANALYZE_URL}>
                                    {<LineChartOutlined />} Analyze
                                </Link>
                            </Menu.Item>
                            <Menu.Item key={COMPARE_URL}>
                                <Link to={COMPARE_URL}>
                                    {<SwapOutlined />} Compare
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </Col>
                    <Col xs={24} sm={{span: BLPInstance.isBloomberg(this.props) ? 7 : 11, offset: BLPInstance.isBloomberg(this.props) ? 3 : 1}} style={{background: '#1c467c'}}>    
                        <div className='right-area'>
                            {/* <Avatar
                                style={{ marginRight: 5 }}
                                size='small'
                                src={
                                    this.props.user && this.props.user.oauth ? (
                                        this.props.user.oauth.picture
                                    ) : (
                                        <UserOutlined />
                                    )
                                }
                            /> */}
                            <span className='top-bar-username'>
                                {this.props.user
                                    ? (BLPInstance.isBloomberg(this.props) ? 'Bloomberg' : this.props.user.email)
                                    : 'Username'}
                            </span>
                            {!BLPInstance.isBloomberg(this.props) && this.getHelpButton()}
                            {this.props.user && this.props.user.is_admin && !BLPInstance.isBloomberg(this.props) && (
                                <Link to={'/admin/users'}>
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        size='small'
                                        icon={<LogoutOutlined />}>
                                        Admin
                                    </Button>
                                </Link>
                            )}
                            {!BLPInstance.isBloomberg(this.props) && <Button
                                onClick={() => {
                                    this.props
                                        .logout(this.props.token)
                                        .then((resp) => {
                                            GAService.logout(true);
                                            this.props.history.push(HOME_URL)
                                        })
                                }}
                                style={{ marginLeft: 10 }}
                                size='small'
                                icon={<LogoutOutlined />}>
                                Sign Out
                            </Button> }
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = ({ auth, entities }) => {
    return {
        token: auth.authToken,
        entities: entities
    }
}

export default connect(mapStateToProps, {})(withRouter(Navbar))
