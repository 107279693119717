import React, { Component } from 'react'
import { Row } from 'antd'
import { Form, Input, Button } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import GAService from '../../GAService'

class LoginForm extends Component {
    onFinish = (values) => {
        this.props.handleLogin(values)
    }

    onFinishFailed = (errorInfo) => {
        // console.log("Failed:", errorInfo);
    }

    render() {
        const { registrationEmail, singleVerification, createPasswordVerification } = this.props
        const isRegistration = !_.isEmpty(registrationEmail)
        
        const passwordVerification = isRegistration ? createPasswordVerification : singleVerification;
        const actionText = isRegistration ? 'Register' : 'Sign in'

        return (
            <>
                <Row className='content_row' justify='center' align='middle'>
                    <h1>{actionText}</h1>
                </Row>
                <Form
                    name='basic'
                    labelCol={{ span: 14, offset: 5 }}
                    wrapperCol={{ span: 14, offset: 5 }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete='off'
                    layout='vertical'
                    initialValues={{
                        username: registrationEmail,
                    }}>
                    <Form.Item
                        label='Username'
                        name='username'
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                            },
                            {
                                validator: (_, value) => validateEmail(value),
                            },
                        ]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label='Password' required>
                        <Form.Item
                            name='password'
                            noStyle
                            rules={passwordVerification}>
                            <Input.Password />
                        </Form.Item>
                    </Form.Item>
                    {isRegistration ? (
                        <Form.Item label='Confirm' required>
                            <Form.Item
                                name='confirm'
                                label='Confirm Password'
                                dependencies={['password']}
                                hasFeedback
                                noStyle
                                rules={this.props.matchPasswordVerification}>
                                <Input.Password />
                            </Form.Item>
                        </Form.Item>
                    ) : (
                        <></>
                    )}

                    <Form.Item>
                        <Button type='primary' block htmlType='submit'>
                            {actionText}
                        </Button>
                        <Button type='secondary' style={{marginTop: 5}} block 
                            onClick={(e) => {
                                this.props.setForgotPassword(true);
                            }}>
                            Forgot/Change Password
                        </Button>
                    </Form.Item>
                </Form>
            </>
        )
    }
}

export default connect(null, {})(LoginForm)

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(String(email).toLowerCase())) return Promise.resolve()
    return Promise.reject(
        new Error('Email addresses not valid, please check your format!')
    )
}

// leaving here in case we want to have single shot verification
// export const validatePassword = (password) => {
//     const re = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%?=*&]).{8,20})/
//     if (re.test(String(password))) return Promise.resolve()
//     return Promise.reject(
//         new Error(
//             'Password is not valid. Passwords must contain 1 upper case, 1 lower case, 1 number, 1 symbol and be between 6 and 98 characters long.'
//         )
//     )
// }
