import { Button, Form, Input, Switch, Select, notification } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { ERROR_COLOR } from '../../../shared/constants'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setModalState } from '../../../features/UI/UIActions'
import _ from 'lodash'
import {
    updateUser,
    createUserWithGroups,
} from '../../../features/user/userActions'

class UsersModal extends Component {
    state = { addGroups: [], removeGroups: [], currGroups: null }

    getInitialGroups = () => {
        return this.props.modalState.initialValues
            ? this.props.modalState.initialValues.groups
            : []
    }

    getInitialGroupIds = () => {
        return this.props.modalState.initialValues
            ? this.props.modalState.initialValues.groups.map((g) => g.id)
            : []
    }

    clearState = () => {
        this.setState({
            addGroups: [],
            removeGroups: [],
            currGroups: null,
        })
    }

    isNewUser = () => {
        return this.props.modalState.newUser
    }

    onUserGroupChange = (values) => {
        //addGroups - any group that is not in intial groups
        //removeGroups - values that are initial group but not current groups
        const initialGroups = this.getInitialGroupIds()
        const newAddGroups = this.state.addGroups
        const newRemoveGroups = this.state.removeGroups
        const groupsNow = []

        for (const value of values) {
            if (
                !initialGroups.includes(value) &&
                !newAddGroups.includes(value)
            ) {
                newAddGroups.push(value)
            }
            groupsNow.push(this.props.groups[value])
        }
        for (const group of initialGroups) {
            if (!values.includes(group) && !newRemoveGroups.includes(group)) {
                newRemoveGroups.push(group)
            }
        }

        this.setState({
            addGroups: newAddGroups,
            removeGroups: newRemoveGroups,
            currGroups: groupsNow,
        })
    }

    onFinish = async (values) => {
        if (this.isNewUser()) {
            const { addGroups } = this.state
            if (!addGroups || addGroups.length === 0) {
                notification.error({
                    duration: 0,
                    placement: 'topRight',
                    message: 'Unable to create User',
                    description:
                        'User must be associated with at least one group',
                    style: {
                        backgroundColor: ERROR_COLOR,
                    },
                })
                return
            }
            this.props.createUserWithGroups(
                this.props.authToken,
                {
                    email: values.email,
                    name: values.name,
                    admin: values.admin,
                },
                addGroups
            )
        } else {
            const addGroups = this.state.addGroups
            const removeGroups = this.state.removeGroups
            const currGroups = this.state.currGroups
            this.props.updateUser(
                this.props.authToken,
                addGroups,
                removeGroups,
                currGroups,
                {
                    ...values,
                },
                this.props.modalState.initialValues.id
            )
        }
        this.props.setModalState('users', {
            visible: false,
            initialValues: null,
            newUser: false,
        })
        this.clearState()
    }

    render() {
        if (!this.props.modalState.visible) return null

        let sortedGroups = _.map(this.props.groups, (g) => g);
        if (sortedGroups) {
            sortedGroups.sort((a, b) => {
                if (a.name && b.name) {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                }
                if (a.name) {
                    return -1;
                }
                return 1;
            })
        }

        return (
            <Modal
                okText={_.noop}
                cancelText={_.noop}
                visible={this.props.modalState.visible}
                onCancel={() => {
                    this.props.setModalState('users', {
                        visible: false,
                        initialValues: null,
                        newUser: false,
                    })
                    this.clearState()
                }}>
                <div style={{ paddingTop: '50px' }}>
                    <Form
                        name='basic'
                        initialValues={{
                            ...this.props.modalState.initialValues,
                        }}
                        onFinish={this.onFinish}>
                        <Form.Item
                            className='admin-form-item'
                            label={'Name'}
                            name='name'>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            className='admin-form-item'
                            label={'Email'}
                            name='email'>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            className='admin-form-item'
                            label={'User Groups'}
                            name='user_groups'>
                            <Select
                                mode='multiple'
                                allowClear
                                style={{ width: '100%' }}
                                placeholder='Please select'
                                filterOption={false}
                                defaultValue={
                                    this.props.modalState.initialValues &&
                                    this.props.modalState.initialValues.groups
                                        ? this.props.modalState.initialValues.groups.map(
                                              (g) => g.id
                                          )
                                        : []
                                }
                                onChange={this.onUserGroupChange}>
                                {
                                    
                                    _.map(sortedGroups, (g) => {
                                    return (
                                        <Select.Option
                                            key={'group-option' + g.id}
                                            value={g.id}>
                                            {g.name}
                                        </Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        {this.props.modalState.initialValues && (
                            <Form.Item
                                className='admin-form-item'
                                label={'Active'}
                                name='active'>
                                <Switch
                                    defaultChecked={
                                        this.props.modalState.initialValues
                                            .active
                                    }
                                />
                            </Form.Item>
                        )}
                        <Form.Item
                            className='admin-form-item'
                            label={'Admin'}
                            name='admin'>
                            <Switch
                                defaultChecked={
                                    this.props.modalState.initialValues
                                        ? this.props.modalState.initialValues
                                              .admin
                                        : false
                                }
                            />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type='primary' htmlType='submit'>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = ({
    groups,
    auth: { authToken },
    UI: {
        modals: { users: modalState },
    },
}) => {
    return {
        modalState,
        authToken,
        groups,
    }
}

export default connect(mapStateToProps, {
    setModalState,
    updateUser,
    createUserWithGroups,
})(withRouter(UsersModal))
