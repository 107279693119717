import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typography, AutoComplete, Row, Col, Button, Spin } from 'antd'
import AnalyzeSchool from './AnalyzeSchool'
import AnalyzeGroup from './AnalyzeGroup'
import { withRouter } from 'react-router'
import _ from 'lodash'
import { LineChartOutlined, CloseOutlined } from '@ant-design/icons'
import {
    setAnalyzeEntity,
    setAnalyzeSchoolEntity,
    setAnalyzeSearchAndRefresh,
    setAnalyzeSchoolSearchAndRefresh,
    setAnalyzeEntityAndLoadData,
    setAnalyzeSchoolEntityAndLoadData,
} from '../../features/entity/entityActions'
import { BACKEND_URL } from '../../shared/constants'
import './Analyze.scss'
import { canExport } from '../../shared/Authorization'
import apiService from '../../features/apiService'

class Analyze extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            targetEntityData: null
        }
    }

    handleClearButton(event) {
        const { analyzeSchoolSearch, setAnalyzeEntity, setAnalyzeSchoolEntity, setAnalyzeSearchAndRefresh, setAnalyzeSchoolSearchAndRefresh } = this.props
        if (analyzeSchoolSearch) {
            setAnalyzeSchoolEntity(null)
            setAnalyzeSchoolSearchAndRefresh(null)
        }
        else {
            setAnalyzeEntity(null)
            setAnalyzeSearchAndRefresh(null)
        }
    }

    getSearchBar(sid) {
        const {
            setAnalyzeEntityAndLoadData,
            setAnalyzeSearchAndRefresh,
            setAnalyzeSchoolEntityAndLoadData,
            setAnalyzeSchoolSearchAndRefresh,
            entities,
        } = this.props
        const { analyzeCandidates, analyzeSearch, analyzeSchoolSearch, all } = entities
        const { items } = analyzeCandidates ? analyzeCandidates : all
        let sortedItems = items.sort((a, b) => {
            return ('' + a.name).localeCompare(b.name);
        });
        const { Option } = AutoComplete

        const refreshSetter = sid ? setAnalyzeSchoolSearchAndRefresh : setAnalyzeSearchAndRefresh;
        const loadSetter = sid ? setAnalyzeSchoolEntityAndLoadData : setAnalyzeEntityAndLoadData
        return (
            <>
                <div style={{ height: 15 }} />
                <AutoComplete
                    style={{
                        width: '99%',
                        marginLeft: 5,
                    }}
                    popupClassName = {'rc-virtual-list-scrollbar'}
                    value={sid ? analyzeSchoolSearch : analyzeSearch }
                    onChange={(searchValue) => {
                        //TODO: debounce
                        refreshSetter(searchValue)
                    }}
                    onSelect={(val) => {
                        const target = _.filter(
                            items,
                            (entity) => entity.id === val
                        )
                        loadSetter(target[0])
                    }}
                    placeholder={'Enter school name'}
                    filterOption={(inputValue, option) => {
                        return (
                            option.children
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                        )
                    }}>
                    {sortedItems.map((school) => (
                        <Option
                            key={`${school.name}-${school.id}`}
                            value={school.id}>
                            {`${school.name} (${school.charter_type}, ${school.state})`}
                        </Option>
                    ))}
                </AutoComplete>
                <div style={{ height: 5 }} />
            </>
        )
    }

    isGroup(sid) {
        if (sid) {
            return false;
        }

        const { analyzeEntity } = this.props.entities;
        return analyzeEntity && (analyzeEntity.charter_type === 'CMO' || analyzeEntity.charter_type === 'Obligated Group')
    }

    buildExportUrl(sid) {
        let entityModalData = sid ? this.props.entities.analyzeSchoolEntity[sid] : this.props.entities.analyzeEntity
        if (entityModalData && entityModalData.school_id) {
            return `${BACKEND_URL}/schools/${entityModalData.school_id}/export?token=${this.props.token}&format=xlsx`
        } else if (entityModalData && entityModalData.cmo_id && this.isGroup(sid)) {
            return `${BACKEND_URL}/cmos/${entityModalData.cmo_id}/export?token=${this.props.token}&format=xlsx`
        } else if (entityModalData && entityModalData.obligated_group_id && this.isGroup(sid)) {
            return `${BACKEND_URL}/obligated_groups/${entityModalData.obligated_group_id}/export?token=${this.props.token}&format=xlsx`
        }else {
            console.error(
                'unknown type of export for entityModal: ' + entityModalData
            )
            return null
        }
    }

    render() {
        const {
            setAnalyzeEntityAndLoadData,
            setAnalyzeSchoolEntityAndLoadData,
        } = this.props

        const { Title } = Typography
        const { analyzeEntity, analyzeData, analyzeSchoolEntity, analyzeSchoolEntityData } = this.props.entities
        const { token, user, entities } = this.props

        const query = new URLSearchParams(this.props.location.search);
        const sid = query.get('sid');

        // with sid we are loading school analyze data from CMO analyze page
        const targetEntity = sid ? analyzeSchoolEntity[sid] : analyzeEntity; 
        const setter = sid ? setAnalyzeSchoolEntityAndLoadData : setAnalyzeEntityAndLoadData; 
        const type = !!targetEntity ? targetEntity.charter_type : null;

        if (!_.isEmpty(targetEntity)) {
            const { name } = targetEntity
            const isSchool = !!targetEntity.school_id
            if ((sid && _.isEmpty(analyzeSchoolEntityData[sid])) || (sid == null && _.isEmpty(analyzeData))) {
                // add that lovely loading glyph
                setter(targetEntity);
                return (
                    <>
                        <Title level={2}>
                            {' '}
                            {<LineChartOutlined />} Analyze: {name}
                        </Title>
                        <Row
                            className="search-row">
                            <Col span={22}>{this.getSearchBar(sid)}</Col>
                            <Col span={1}>
                                <Button
                                    style={{
                                        paddingLeft: 5,
                                        paddingRight: 10,
                                        marginTop: 15,
                                        // width: '99%',
                                    }}
                                    icon={<CloseOutlined />}
                                    onClick={(e) => this.handleClearButton(e)}>
                                    Clear
                                </Button>
                            </Col>
                        </Row>
                        <Spin size='large' style={{ paddingTop: 10 }} />
                    </>
                )
            }
            return (
                <>
                    <Title level={2}>
                        {' '}
                        {<LineChartOutlined />} Analyze: {name}
                    </Title>
                    <Row
                        className="search-row">
                        <Col xs={24} sm={19}>{this.getSearchBar()}</Col>
                        <Col sm={1}>
                            <Button
                                style={{
                                    paddingLeft: 5,
                                    paddingRight: 10,
                                    marginTop: 5,
                                    // width: '99%',
                                }}
                                icon={<CloseOutlined />}
                                onClick={(e) => this.handleClearButton(e)}>
                                Clear
                            </Button>
                        </Col>
                        <Col sm={1}>
                            {canExport(this.props) && 
                            <Button
                                className='export-button'
                                style={{ marginTop: 5 }}
                                onClick={() =>
                                    window.open(this.buildExportUrl(sid), '_blank')
                                }
                                type='secondary'>
                                Export
                            </Button>}
                        </Col>
                    </Row>
                    
                    {isSchool ? (
                        <AnalyzeSchool
                            data={sid ? analyzeSchoolEntityData[sid] : analyzeData}
                            entityID={targetEntity.school_id}
                            entities={entities}
                            token={token}
                            user={user}
                        />
                    ) : (
                        <AnalyzeGroup
                            data={analyzeData}
                            entityID={type === 'CMO' ? targetEntity.cmo_id : targetEntity.obligated_group_id}
                            entities={entities}
                            token={token}
                            user={user}
                            type={type}
                        />
                    )}
                </>
            )
        } else {
            return (
                <>
                    <Title level={2}> {<LineChartOutlined />} Analyze</Title>
                    {this.getSearchBar()}
                </>
            )
        }
    }
}

const mapStateToProps = ({ entities }) => {
    return {
        entities,
    }
}

export default connect(mapStateToProps, {
    setAnalyzeEntity,
    setAnalyzeEntityAndLoadData,
    setAnalyzeSearchAndRefresh,
    setAnalyzeSchoolEntity,
    setAnalyzeSchoolSearchAndRefresh,
    setAnalyzeSchoolEntityAndLoadData
})(withRouter(Analyze))
