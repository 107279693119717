import React, { Component } from 'react'
import { Row } from 'antd'
import { Form, Input, Button } from 'antd'
import { connect } from 'react-redux'
import _ from 'lodash'

class ForgotPasswordForm extends Component {
    state = {
        errorMessage: ''
    }

    onFinish = async (values) => {
        try {
           await this.props.handleForgotPassword(values)
        }
        catch (err) {
            this.setState({errorMessage: 'Too many attempts. Pleasae try again after some time'})
        }
    }

    onFinishFailed = (errorInfo) => {
        // console.log("Failed:", errorInfo);
    }

    render() {
        const { registrationEmail } = this.props
        
        const actionText = 'Forgot Password'

        return (
            <>
                <Row className='content_row' justify='center' align='middle'>
                    <h1>{actionText}</h1>
                </Row>
                <Row className='content_row' justify='center' align='middle'>
                    {this.state.errorMessage.length > 0 && <div style={{color: 'red'}}>Password reset failed. Please try again later</div>}
                </Row>
                <Form
                    name='basic'
                    labelCol={{ span: 14, offset: 5 }}
                    wrapperCol={{ span: 14, offset: 5 }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete='off'
                    layout='vertical'
                    initialValues={{
                        username: registrationEmail,
                    }}>
                    <Form.Item
                        label='Username'
                        name='username'
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                            },
                            {
                                validator: (_, value) => validateEmail(value),
                            },
                        ]}>
                        <Input />
                    </Form.Item>


                    <Form.Item>
                        <Button type='primary' block htmlType='submit'>
                            Continue
                        </Button>
                        <Button type='secondary' style={{marginTop: 5}} block onClick={(e) => {this.props.setForgotPassword(false)}}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            </>
        )
    }
}

export default connect(null, {})(ForgotPasswordForm)

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(String(email).toLowerCase())) return Promise.resolve()
    return Promise.reject(
        new Error('Email addresses not valid, please check your format!')
    )
}

// leaving here in case we want to have single shot verification
// export const validatePassword = (password) => {
//     const re = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%?=*&]).{8,20})/
//     if (re.test(String(password))) return Promise.resolve()
//     return Promise.reject(
//         new Error(
//             'Password is not valid. Passwords must contain 1 upper case, 1 lower case, 1 number, 1 symbol and be between 6 and 98 characters long.'
//         )
//     )
// }
