import { message, notification } from 'antd'
import { ERROR_COLOR } from '../../shared/constants'
import ApiService from '../apiService'
import { logout } from '../auth/authActions'
import _ from 'lodash'
import { SET_USERS, UPDATE_USER, EMAIL_TYPES } from './userConstants'

// Dashboard
export const setUsers = (users) => {
    return {
        type: SET_USERS,
        users,
    }
}

export const updateUserAction = (userData, userId) => {
    return {
        type: UPDATE_USER,
        userData,
        userId,
    }
}

export const createUserWithGroups = (token, userData, groups) => async (
    dispatch
) => {
    try {
        await ApiService.postUser(token, userData).then((resp) => {
            if (!resp || !resp.data) {
                notification.error({
                    duration: 0,
                    placement: 'topRight',
                    message: 'Unable to add user to group',
                    description: 'Please contact system adminstrator.',
                    style: {
                        backgroundColor: ERROR_COLOR,
                    },
                })
                return Promise.reject()
            }

            const userId = resp.data.id
            const promises = []
            groups.forEach((gid) => {
                promises.push(ApiService.addUserToGroup(token, userId, gid))
            })
            return promises
        })
    } catch (e) {
        message.error('Error creating user or adding user to group')
    }
}

export const updateUser = (
    token,
    addGroups,
    removeGroups,
    currGroups,
    userData,
    userId
) => async (dispatch) => {
    try {
        const promises = []
        addGroups.forEach((id) =>
            promises.push(ApiService.addUserToGroup(token, userId, id))
        )
        removeGroups.forEach((id) =>
            promises.push(ApiService.removeUserFromGroup(token, userId, id))
        )
        await Promise.all(promises)
        const userDataReq = _.omit(userData, 'user_groups')

        const updateRes = await ApiService.updateUser(
            token,
            userDataReq,
            userId
        )
        const { data } = updateRes
        const final = { ...data, groups: currGroups }
        dispatch(updateUserAction(final, userId))
        return data
    } catch (e) {
        message.error('Error updating user', 2)
    }
}

export const resendConfirmationEmail = (email, token) => async (
    dispatch
) => {
    try {
        if (email && email.length > 0) {
            let data = {};
            data.email = email;
            data.type = EMAIL_TYPES['confirmation'];
            let success = [];
            await ApiService.sendUserEmails(data, token).then((resp) => {

                success = resp.data;
                if (!resp || resp.status !== 200) {
                    notification.error({
                        duration: 0,
                        placement: 'topRight',
                        message: 'Unable to resend confirmation email',
                        description: 'Please contact system adminstrator.',
                        style: {
                            backgroundColor: ERROR_COLOR,
                        },
                    })
                    return Promise.reject()
                }
                return success;
            })
        }
        else {
            message.error("Please enter a valid email.");
        }
    } catch (e) {
        message.error('Error resending confirmation email.')
    }
}

export const sendPasswordResetEmail = (email, token) => async (
    dispatch
) => {
    try {
        if (email && email.length > 0) {
            let data = {};
            data.email = email;
            data.type = EMAIL_TYPES['password_reset'];
            let success = [];
            await ApiService.sendUserEmails(data, token).then((resp) => {
                
                success = resp.data;
                if (!resp || resp.status !== 200) {
                    notification.error({
                        duration: 0,
                        placement: 'topRight',
                        message: 'Password reset failed',
                        description: 'Please contact system adminstrator.',
                        style: {
                            backgroundColor: ERROR_COLOR,
                        },
                    })
                    return Promise.reject()
                }
                
                return success;
            })
        }
        else {
            message.error("Please enter a valid email.");
        }
    } catch (e) {
        message.error('Error sending password reset email.')
    }
}

export const deleteUser = (token, id, users) => async (dispatch) => {
    try {
        await ApiService.deleteUser(token, id).then((resp) => {

            if (!resp || resp.status !== 200) {
                let msg = resp && resp.response && resp.response.data && resp.response.data.message || 'User deletion failed';
                notification.error({
                    duration: 0,
                    placement: 'topRight',
                    message: msg,
                    description: 'Please contact system adminstrator.',
                    style: {
                        backgroundColor: ERROR_COLOR,
                    },
                })
                return Promise.reject()
            }

            if (users) {
                let final = {}
                for (let key in users) {
                    if (parseInt(key) !== id) {
                        let u = users[key];
                        final[key] = u;
                    }
                }
                dispatch(setUsers(final));
                return final;
            };
        })
    }
    catch (error) {
        message.error('Error deleting user.');
    }
}

export const getAndSetUsers = (token) => async (dispatch) => {
    try {
        const getUsersRes = await ApiService.getUsers(token)
        const { data } = getUsersRes

        let final = {}
        data.forEach((u) => {
            final[u.id] = u
        })

        dispatch(setUsers(final))
        return data
    } catch (error) {
        console.warn(error)
        logout()
        notification.error({
            duration: 0,
            placement: 'topRight',
            message: 'Login Error',
            description: 'Please try logging out and in again.',
            style: { backgroundColor: ERROR_COLOR },
        })
    }
}
