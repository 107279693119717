import { notification } from 'antd'
import { ERROR_COLOR } from '../../shared/constants'
import ApiService from '../apiService'
import { logout } from '../auth/authActions'
import { ADD_GROUP, SET_GROUPS, UPDATE_GROUP } from './groupConstants'

export const setGroups = (groups) => {
    return {
        type: SET_GROUPS,
        groups,
    }
}

export const addGroup = (groupData) => {
    return {
        type: ADD_GROUP,
        groupData,
    }
}

export const updateGroupAction = (groupData, groupId) => {
    return {
        type: UPDATE_GROUP,
        groupData,
        groupId,
    }
}

export const getAndSetGroups = (token) => async (dispatch) => {
    try {
        const getGroupsRes = await ApiService.getGroups(token)
        const { data } = getGroupsRes
        let final = {}
        data.forEach((g) => {
            final[g.id] = g
        })
        dispatch(setGroups(final))
        return data
    } catch (error) {
        console.warn(error)
        logout()
        notification.error({
            duration: 0,
            placement: 'topRight',
            message: 'Login Error',
            description: 'Please try logging out and in again.',
            style: { backgroundColor: ERROR_COLOR },
        })
    }
}

export const postNewGroup = (token, groupData) => async (dispatch) => {
    try {
        const postGroupRes = await ApiService.postGroup(token, groupData)
        const { data } = postGroupRes
        dispatch(addGroup(data))
        return data
    } catch (error) {
        console.warn(error)
        logout()
        notification.error({
            duration: 0,
            placement: 'topRight',
            message: 'Login Error',
            description: 'Please try logging out and in again.',
            style: { backgroundColor: ERROR_COLOR },
        })
    }
}

export const updateGroup = (token, groupData, groupId) => async (dispatch) => {
    try {
        const updateGroupRes = await ApiService.updateGroup(
            token,
            groupData,
            groupId
        )
        const { data } = updateGroupRes
        dispatch(updateGroupAction(data, groupId))
        return data
    } catch (error) {
        console.warn(error)
    }
}
