import auth from "./bb-apps-authentication.js";
import {
    BSSO_CLIENT_ID,
    BACKEND_URL,
    BSSO_REDIRECT,
    BSSO_TOKEN_URL,
} from './shared/constants'
import axios from 'axios'

class BloombergService {
    constructor() {
        this.state = '';
        this.nonce = '';
    }

    isBloomberg = (props) => {
        if (props && props.source) {
            return props.source === 'bloomberg';
        }
        return props && props.entities && props.entities.context && props.entities.context.source === 'bloomberg';
    }

    getAuthorizationCode = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        let code = params['code'];
        return code;
    }

    bssoOAuth2Signup = async () => {
        // https://stackoverflow.com/questions/46844285/difference-between-oauth-2-0-state-and-openid-nonce-parameter-why-state-cou
        let state = Date.now();
        let nonce = 'bsso-' + this.state.toString();
        localStorage.setItem('sip-bsso-state', state);
        localStorage.setItem('sip-bsso-nonce', nonce);
        let redirectUrl = BSSO_REDIRECT;

        return window.BB.Apps.Auth.OAuth2.signOn({
            clientId: BSSO_CLIENT_ID,
            scopes: ['openid', 'profile'],
            redirectURI: redirectUrl,
            state: this.state,
            nonce: this.nonce
        });
    }

    bssoHandleOAuth2Callback = async (authorizationCode, state) => {
        // extract autorization code and state
        //let storedState = localStorage.getItem('sip-bsso-state');
        let data = {authorizationCode: authorizationCode, redirectUrl: BSSO_REDIRECT};
        const resp = await axios({
            method: 'POST',
            url: `${BACKEND_URL}${BSSO_TOKEN_URL}`,
            data
        });


        // sample response
        // {"access_token":"eyJhbGciOiJSUzI1NiIsImtpZCI6ImJzc29DZXJ0IiwicGkuYXRtIjoiaWYyNCJ9.eyJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIl0sImNsaWVudF9pZCI6InNpcC1ic3NvLW9hdXRoMiIsImlzcyI6Imh0dHBzOi8vYnNzby5ibG9vbWJlcmcuY29tIiwicHZmbGV2ZWxzIjoiTk9fUFZGX0RBVEEiLCJzZXNzaW9uSWQiOiIzREIzQ0ZERTE3RUZGMTRCRTQ0QzYwOUMwODVGMjEzMC0xMDIxOC0xIiwiZW1wbG95ZWVpZCI6IjMxMjg4NDE3IiwidXNlcm5hbWUiOiJuamlhbmc4MyIsImV4cCI6MTY2MTIwNjM4M30.Fn1RzqVNyfCcP1gLGA2FztA-RKbaa66cQ9ExOQRJ88H-OT1TfIiTcyXCXVEvk8DTS-AoBFnTvgeUpm8uvDOQaH02OqEL0rcBAPCvGF7CylckiswuekVU3YYXLl9CCJtBiWcRWagXw0tci1mPsMxt0Rqw-mPYgBEX55MSdLyzs7UQnUpJ2NTxd_tBoLFs5lx1T5v0F-VjxYGfGGJadn_6zWE-XAK3-UmP4tiezetmifPn5XwIM_jcr1Wy6y5tlk2Q9bkhHnitOomPsB_TFPzlUl1jiLGiQ8VszBLeGySo8sacl9VRGdKc4Nbu_TW7jHEc3SER-cD4wQtP82YyFEt9xFmb9pB5FSOymjrUBDmCQEkw4JfjbvUCsJvKINi0UQ2e5gUMyAP05GHnga5aZ04MEaMkkBG4Qt-39hWv3Fa_xQuuY-Tu2S-r4ITTb8EAFcWSkqhBnPxcjPzQeSy1_j2QlmSkqYbYWeAmpRNxzPo4E6WAplA_5drqxsAGA9lVWfjjhbRKMecwqJ6fMoitqEZeJv-l_flFfDq_a-aQbObnyzdkH9UQgiogTbuXRdp3Ih1PUAxN2lMbY9YAnXCI3hBzir0IbF9s2yA_ZBNRso4q7iKhDrUOcXHa7MDBgoTrSa6xgfqihUpaRkKk9s8ZiYCXpQzy0yUO7K3BT8qVOt_c7pU","id_token":"eyJhbGciOiJSUzI1NiIsImtpZCI6IkRfUTl2S1FIZGhPQXdTc2I0NEZrRVlOcmdUMCJ9.eyJzdWIiOiJuamlhbmc4MyIsImF1ZCI6InNpcC1ic3NvLW9hdXRoMiIsImp0aSI6IklJZmw1b0lCT2Ywb0ZKYjU4RGhKelAiLCJpc3MiOiJodHRwczovL2Jzc28uYmxwcHJvZmVzc2lvbmFsLmNvbSIsImlhdCI6MTY2MTIwNjMxNywiZXhwIjoxNjYxMjA2NjE3LCJmaXJzdE5hbWUiOiJOaW5nIiwibGFzdE5hbWUiOiJKaWFuZyIsImVtYWlsQWRkcmVzcyI6Im5qaWFuZzgzQGJsb29tYmVyZy5uZXQiLCJmaXJtSWQiOiI5NTg5MDAiLCJsb2dpblR5cGUiOiIxMDAiLCJ1dWlkIjoiMzEyODg0MTciLCJub25jZSI6IlV0Y1Z2cEgzQjZVRUdFR1hKT05rIn0.faC9Ag_iN0BDziptVj-4DBuoGkbCLSPNIg5ljYmc6I0sGyGPlBJjeN01UeMW3jw7KnSvvtjMXfAXDiQ5hAbsqOhbsNt8eOlCGmQrDnIa59rvva4vZ819A3aABXbZeY_o2F68BCn1IwaVVA5owsnfR_vU4x6rG_rDDGlMqh2Zjhvnzuu8Jvuj__xFu5qym9RU76esLJu5zoX9eOnQS72CGnn8n2KwxtNe_ITM9W0Kc00IaoveN-cbemBFli6tfgulYdnNfbyvuqizATCWk8ikdGgScWCvv_GEm5r5mohXFBEixfGJHI2O3N7N5VU3PD2FcifcoBJHmIbrBY_rRfE3ow","token_type":"Bearer","expires_in":59}'

        if (resp.status === 200) {
            return resp.data;
        }
        
        return null;
    }
}

const BLPInstance  = new BloombergService();
//Object.freeze(BLPInstance);  // singleton
export default BLPInstance;