import authReducer from './auth/authReducer'
import entityReducer from './entity/entityReducer'
import users from './user/userReducer'
import groups from './group/groupReducer'
import UI from './UI/UIReducer'

import { combineReducers } from 'redux'

export default combineReducers({
    auth: authReducer,
    entities: entityReducer,
    users,
    groups,
    UI,
})
