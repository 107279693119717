import { Form, Input, Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import { notification, Space, Card, Switch  } from 'antd';
import { ERROR_COLOR } from '../../shared/constants'
import { DownOutlined } from '@ant-design/icons';
import './compare.scss';

import {
    ANALYZE_URL,
    MISSING_METADATA_STR,
    BACKEND_URL
} from '../../shared/constants'

import {
    LineChartOutlined,
    DownloadOutlined,
} from '@ant-design/icons'

import {
    setCompareFilters,
    clearCompareEntities
} from '../../features/entity/entityActions'

import _ from 'lodash'

import GAService from '../../GAService';
import BLPInstance from '../../BloombergService';

class SearchOptionsModal extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.compareFilters = props.entities.compareFilters;
        this.state = {
            daysCashOnHandFilter: false,
            enrollmentDropFilter: false,
            negativeIncomeFilter: false,
            selectedState: '0',
            locationType: 'state',
            zipcode: '',
            radius: '20',
        }
    }

    componentDidMount() {
        const locationType = this.compareFilters && this.compareFilters.locationType ? this.compareFilters.locationType : 'state';
        this.setState({
            daysCashOnHandFilter: this.compareFilters && this.compareFilters.daysCashOnHandFilter ? true : false, 
            enrollmentDropFilter: this.compareFilters && this.compareFilters.enrollmentDropFilter ? true : false, 
            negativeIncomeFilter: this.compareFilters && this.compareFilters.negativeIncomeFilter ? true : false, 
            selectedState: this.compareFilters && this.compareFilters.state && this.compareFilters.state.length > 0 ? this.compareFilters.state : '0',
            locationType: locationType,
            zipcode: this.compareFilters && this.compareFilters.zipcode ? this.compareFilters.zipcode : '',
            radius: locationType === 'proximity' && !!this.compareFilters.radius ? this.compareFilters.radius : '20'
        });
    }

    layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    handleSearch = () => {
        let targetSelected = false;
        let {compareFilters} = this.props.entities;
        if (!compareFilters) {
            compareFilters = {};
            this.setState({selectedState: '0', zipCode: ''});
        }

        compareFilters.locationType = this.state.locationType;
        if (this.state.locationType === 'state') {
            if (this.state.selectedState !== '0') {
                targetSelected = true;
                //delete compareFilters['zipcode'];
                //delete compareFilters['radius'];
                compareFilters.state = this.state.selectedState;
                GAService.compare('Compare', 'state');
            }
            else {
                compareFilters.state = '';
            }
        }
        else if (this.state.locationType === 'proximity') {
            if (!!this.state.zipcode && parseInt(this.state.radius) > 0) {
                targetSelected = true;                
                //delete compareFilters['state'];
                compareFilters.zipcode = this.state.zipcode;
                compareFilters.radius = this.state.radius;
                GAService.compare('Compare', 'zipcode');
            }
        }

        if (this.state.daysCashOnHandFilter) {
            targetSelected = true;
            compareFilters['daysCashOnHandFilter'] = true;
            GAService.compare('Compare', 'cash incompliance');
        }
        else {
            compareFilters['daysCashOnHandFilter'] = null;
        }

        if (this.state.negativeIncomeFilter) {
            targetSelected = true;
            compareFilters['negativeIncomeFilter'] = true;
            GAService.compare('Compare', 'negative income');
        }
        else {
            compareFilters['negativeIncomeFilter'] = null;
        }

        if (this.state.enrollmentDropFilter) {
            targetSelected = true;
            compareFilters['enrollmentDropFilter'] = true;
            GAService.compare('Compare', 'enrollment drop');
        }
        else {
            compareFilters['enrollmentDropFilter'] = null;
        }
        
        if (!targetSelected) {
            // warning
            notification.error({
                duration: 0,
                placement: 'topRight',
                message: 'Compare Warning',
                description: 'Please select a filter and search again.',
                style: { backgroundColor: ERROR_COLOR },
            });

            return false;
        }
        compareFilters.limit = 100;
        this.props.setLoading(true);
        this.props.setCompareFilters(compareFilters);
        this.props.search(compareFilters);
        return true;
    }

    onClear = () => {
        this.setState({
            daysCashOnHandFilter: false,
            enrollmentDropFilter: false,
            negativeIncomeFilter: false,
            selectedState: '0',
            locationType: 'state',
            zipcode: '',
            radius: '20',
        }); 

        this.formRef.current.resetFields();

        let {compareFilters} = this.props.entities;
        compareFilters = {};
        this.props.setCompareFilters(null);
        this.props.clearCompareEntities();
        return true;
    }

    onFinish = (values) => {
        console.log(values)
    }

    onFinishFailed = () => {
        console.log('bad input')
    }

    onStateSelected = (ev) => {
        this.setState({selectedState: ev.target.value});
    }

    locationTypeChanged = (ev) => {
        this.setState({
            locationType : ev.currentTarget.value
        });

        if (ev.currentTarget.value === 'proximity') {
            //this.setState({radius: '20', locationType : ev.currentTarget.value});
            //this.formRef.current.setFieldsValue({radius: '20'});
        }
        else {
            //this.setState({radius: '', locationType : ev.currentTarget.value});
            //this.formRef.current.setFieldsValue({radius: ''});
        } 
    }

    onZipChange = (event) => {
        this.setState({
            zipcode: event.target.value
        });
    }

    onRadiusChange = (event) => {
        this.setState({
            radius: event.target.value
        });
    }

    onCashCovenantChecked = (checked) => {
        this.setState({
            daysCashOnHandFilter: checked
        });
    }

    onNegativeIncomeChecked = (checked) => {
        this.setState({
            negativeIncomeFilter: checked
        });
    }

    onEnrollmentDropChecked = (checked) => {
        this.setState({
            enrollmentDropFilter: checked
        });
    }
    
    render() {
        const { active_states } = this.props.entities.all;
    
        let menuItems = [];
        active_states.forEach(state => {
            let item = {label: state.abbreviation, key: state.abbreviation}
            menuItems.push(item);
        });

        return (
            <>
                <Modal
                    className='details-modal'
                    title={`Compare Search Options`}
                    visible='true'
                    onOk={() => {
                        if (this.handleSearch()) {
                            this.props.close();
                        }
                    }}
                    onCancel={() => {
                        this.props.close();
                    }}
                    destroyOnClose={true}
                    okButtonProps={{
                        icon: <LineChartOutlined />,
                        type: 'normal',
                    }}
                    cancelButtonProps={{
                        //icon: <DownloadOutlined />,
                        //className: 'export-button',
                        //style: { float: 'left', display: (canExport(this.props)) ? 'block' : 'none' },
                        onClick: () => {
                            this.props.close();
                        }
                    }}
                    okText='Search'>
                    <Form
                        ref={this.formRef}
                        {...this.layout}
                        name='basic'
                        initialValues={{ remember: true }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}>
                        <Card title="Location" style={{ width: 450 }}>   
                            <div className='search-location-radio'>
                                <input type="radio" name="location" id="radio-state" value="state" 
                                    onChange={this.locationTypeChanged}                                
                                    checked={this.state.locationType === 'state'} /><label htmlFor="radio-state">&nbsp;State</label>
                            </div>
                            
                            <div
                                className='dashboard-modal-form-item '
                                style={{textAlign: 'center', marginLeft: '30px', marginBottom: '5px'}}
                                label=''
                                name='state'                                
                                >                                
                                <select style={{border: 'none', color: this.state.locationType === 'state' ? 'black': 'lightgray' }} disabled={this.state.locationType !== 'state'} onChange={this.onStateSelected} value={this.state.selectedState}>
    
                                    <option value="0">Please choose a state</option>
                                    {active_states.map((state, index) => {
                                        return <option value={state.abbreviation} key={state.abbreviation} >
                                            {state.abbreviation}
                                        </option>
                                    })}
                                </select>
                            </div>
                            
                            <div className='search-location-radio' style={{marginBottom: '15px'}}>
                                <input type="radio" id="radio-zip" name="location" value="proximity"
                                    onChange={this.locationTypeChanged} 
                                    checked={this.state.locationType === 'proximity'} /><label htmlFor="radio-zip">&nbsp;Proximity</label>
                            </div>
                            <div class="ant-row ant-form-item dashboard-modal-form-item ">
                                <div class="ant-col ant-col-8 ant-form-item-label">
                                    <label>Zipcode</label>   
                                </div>
                                <div
                                    className='dashboard-modal-form-item '
                                    label='Zipcode'
                                    name='zipcode'
                                    initialValue={this.state.zipcode}
                                    >
                                    <Input type="text" style={{color: this.state.locationType !== 'proximity' ? 'lightgray': 'black' }} disabled={this.state.locationType !== 'proximity'} onChange={this.onZipChange} value={this.state.zipcode} />
                                </div>
                            </div>
                            <div class="ant-row ant-form-item dashboard-modal-form-item ">
                                <div class="ant-col ant-col-8 ant-form-item-label">
                                    <label>Radius</label>   
                                </div>
                                <div
                                    className='dashboard-modal-form-item '
                                    label='Radius'
                                    name='radius'
                                    initialValue={this.state.radius}
                                    >
                                    
                                    <Input type="text" style={{color: this.state.locationType !== 'proximity' ? 'lightgray': 'black' }} onChange={this.onRadiusChange} disabled={this.state.locationType !== 'proximity'} value={this.state.radius} />
                                </div>
                            </div>
                            
                        </Card>
                        <Card title="Filters" style={{marginTop: '10px', width: '450px'}}>
                            
                            <Form.Item
                                className='dashboard-modal-form-item '
                                label="Days' cash below 45"
                                labelCol={{span: 16}}
                                labelAlign="left"
                                name='cashCovnent'
                                initialValue={this.state.daysCashOnHandFilter}
                                >
                                <Switch style={{marginLeft: '30px'}} checked={this.state.daysCashOnHandFilter} onChange={this.onCashCovenantChecked} />
                            </Form.Item>

                            <Form.Item
                                className='dashboard-modal-form-item '
                                label='Negative Net Income'
                                labelCol={{span: 16}}
                                labelAlign="left"
                                name='negativeIncome'
                                initialValue={this.state.negativeIncomeFilter}
                                >
                                <Switch style={{marginLeft: '30px'}} checked={this.state.negativeIncomeFilter} onChange={this.onNegativeIncomeChecked} />
                            </Form.Item>

                            <Form.Item
                                className='dashboard-modal-form-item '
                                label='Enrollment Declined By 5% or More'
                                labelAlign="left"
                                name='enrollmentDrop'
                                labelCol={{span: 16}}
                                initialValue={this.state.enrollmentDropFilter}
                                >
                                <Switch style={{marginLeft: '30px'}} checked={this.state.enrollmentDropFilter} onChange={this.onEnrollmentDropChecked} />
                            </Form.Item>
                            
                        </Card>
                        <Button onClick={this.onClear} style={{marginLeft: '40%', marginTop: '20px'}}>
                            Clear
                        </Button>
                    </Form>
                </Modal>
            </>
        )
    }
}


const mapStateToProps = ({ entities }) => {
    return {
        entities,
    }
}

//export default withRouter(SearchOptionsModal)

export default connect(mapStateToProps, {
    setCompareFilters,
    clearCompareEntities
})(withRouter(SearchOptionsModal))