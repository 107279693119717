export const BACKEND_URL =
    window.location.hostname === 'localhost'
        ? 'http://localhost:5000/python_api'
        : window.location.hostname === 'cms.schoolimprovementpartnership.com'
        ? 'https://data.schoolimprovementpartnership.com/python_api'
        : window.location.hostname === 'dev-cms.schoolimprovementpartnership.com'
        ? 'https://dev-data.schoolimprovementpartnership.com/python_api'
        : window.location.hostname === 'cms.sip-webapp.com'
        ? 'https://api.sip-webapp.com/python_api'
        : 'https://dev-api.sip-webapp.com/python_api'

export const HOME_URL = '/'
export const INACTIVE_LOGIN_URL = '/inactive/'
export const ANALYZE_URL = '/analyze/'
export const COMPARE_URL = '/compare/'
export const ADMIN_URL = '/admin/'

export const NO_AUTH_URLS = [HOME_URL, INACTIVE_LOGIN_URL]
export const LOGIN_ENDPOINT = `${BACKEND_URL}/oauth`
export const LOGOUT_ENDPOINT = `${BACKEND_URL}/oauth/logout`
export const REFRESH_TOKEN_ENDPOINT = `${BACKEND_URL}/oauth/refresh`

export const ERROR_COLOR = '#FFFFFF'
export const MISSING_METADATA_STR = 'N/A'
export const NEVER_EXPIRING_CHARTER_STR = 'Evergreen'

export const DEFAULT_TABLE_RENDER = (text, record) => {
    return text ? text : MISSING_METADATA_STR
}

export const ADMIN_EMAIL = 'cms@schoolimprovementpartnership.net'
export const MAILTO = `mailto:${ADMIN_EMAIL}?subject=Application Access&body=To whom it may concern:%0D%0A%0D%0APlease make this account active so that it can access the SIP Database application at dev-cms.schoolimprovementpartnership.com.`

export const GA_TRACKID = window.location.hostname === 'cms.schoolimprovementpartnership.com' ? 'G-TG64PCJMNM' : 'G-9CBLMZVZN1';
export const GA_DEBUG = (window.location.hostname !== 'cms.schoolimprovementpartnership.com');

export const BSSO_CLIENT_ID = 'sip-bsso-oauth2'
export const BSSO_REDIRECT = window.location.hostname === 'localhost'
                             ? 'http://localhost:3000'
                             : window.location.hostname === 'cms.schoolimprovementpartnership.com'
                             ? 'https://cms.schoolimprovementpartnership.com'
                             : window.location.hostname === 'dev-cms.schoolimprovementpartnership.com'
                             ? 'https://dev-cms.schoolimprovementpartnership.com'
                             : window.location.hostname === 'cms.sip-webapp.com'
                             ? 'https://cms.sip-webapp.com'
                             : 'https://dev-cms.sip-webapp.com'
export const BSSO_TOKEN_URL = '/oauth/bsso/';