import { Button, Form, Input, Switch } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { postNewGroup, updateGroup } from '../../../features/group/groupActions'
import { setModalState } from '../../../features/UI/UIActions'

class GroupModal extends Component {
    state = {}

    onFinish = (values) => {
        if (this.props.modalState.newGroup) {
            this.props.postNewGroup(this.props.authToken, {
                name: values.name,
                full_subscription: true,
            })
        } else {
            this.props.updateGroup(
                this.props.authToken,
                {
                    ...values,
                },
                this.props.modalState.initialValues.id
            )
        }
        this.props.setModalState('group', {
            visible: false,
            initialValues: null,
            newGroup: false,
        })
    }

    render() {
        if (!this.props.modalState.visible) return null
        return (
            <Modal
                okText={<></>}
                cancelText={<></>}
                visible={this.props.modalState.visible}
                onCancel={() => {
                    this.props.setModalState('group', {
                        visible: false,
                        initialValues: null,
                        newGroup: false,
                    })
                }}>
                <Form
                    name='basic'
                    initialValues={{ ...this.props.modalState.initialValues }}
                    onFinish={this.onFinish}>
                    <Form.Item
                        className='admin-form-item'
                        label={'Group Name'}
                        name='name'
                        initialValue={
                            this.props.modalState.initialValues
                                ? this.props.modalState.initialValues.name
                                : ''
                        }>
                        <Input
                            defaultValue={
                                this.props.modalState.initialValues
                                    ? this.props.modalState.initialValues.name
                                    : ''
                            }
                        />
                    </Form.Item>
                    {this.props.modalState.initialValues && (
                        <Form.Item
                            className='admin-form-item'
                            label={'Active'}
                            name='active'
                            initialValue={
                                this.props.modalState.initialValues.active
                            }>
                            <Switch
                                defaultChecked={
                                    this.props.modalState.initialValues.active
                                }
                            />
                        </Form.Item>
                    )}
                    <Form.Item
                        className='admin-form-item'
                        label={'Full Subscription'}
                        name='full_subscription'
                        initialValue={
                            this.props.modalState.initialValues
                                ? this.props.modalState.initialValues
                                      .full_subscription
                                : false
                        }>
                        <Switch
                            defaultChecked={
                                this.props.modalState.initialValues
                                    ? this.props.modalState.initialValues
                                          .full_subscription
                                    : false
                            }
                        />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type='primary' htmlType='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = ({
    auth: { authToken },
    UI: {
        modals: { group: modalState },
    },
}) => {
    return {
        modalState,
        authToken,
    }
}

export default connect(mapStateToProps, {
    setModalState,
    postNewGroup,
    updateGroup,
})(withRouter(GroupModal))
