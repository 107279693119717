export const USERS_ENDPOINT = '/users/'
export const USER_GROUPS_ENDPOINT = '/user_groups/'

export const SET_USERS = 'SET_USERS'
export const UPDATE_USER = 'UPDATE_USER'
export const FINALIZE_USER_ENDPOINT = '/finalize_user'
export const EMAIL_ENDPOINT = '/email'

export const EMAIL_TYPES = {
    confirmation: 0,
    password_reset: 1
}