import { Button, Layout } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, withRouter, Switch, Link } from 'react-router-dom'
import { getAndSetGroups } from '../../features/group/groupActions'
import { getAndSetUsers } from '../../features/user/userActions'
import './admin.scss'
import Groups from './content/Groups'
import Users from './content/Users'
import GroupModal from './modals/GroupModal'
import UsersModal from './modals/UsersModal'

const { Sider, Content } = Layout

class AdminScreen extends Component {
    state = {}

    componentDidMount() {
        this.props.getAndSetUsers(this.props.token)
        this.props.getAndSetGroups(this.props.token)
    }

    render() {
        return (
            <div className='sip-admin-screen-container'>
                <Layout style={{ height: '100%', overflow: 'scroll' }}>
                    <Sider className='admin-sidebar'>
                        <Link to={'/admin/groups'}>
                            <Button type='secondary' className='sidebar-button'>
                                Group Management
                            </Button>
                        </Link>
                        <Link to={'/admin/users'}>
                            <Button type='secondary' className='sidebar-button'>
                                User Management
                            </Button>
                        </Link>
                    </Sider>
                    <Layout>
                        <Content>
                            <Switch>
                                <Route
                                    render={(props) => (
                                        <Groups
                                            {...props}
                                            token={this.props.token}
                                        />
                                    )}
                                    path={'/admin/groups'}
                                />
                                <Route
                                    render={(props) => (
                                        <Users
                                            {...props}
                                            token={this.props.token}
                                        />
                                    )}
                                    path={'/admin/users'}
                                />
                            </Switch>
                        </Content>
                    </Layout>
                </Layout>
                <GroupModal />
                <UsersModal />
            </div>
        )
    }
}

const mapStateToProps = ({ auth }) => {
    return {
        token: auth.authToken,
    }
}

export default connect(mapStateToProps, {
    getAndSetUsers,
    getAndSetGroups,
})(withRouter(AdminScreen))
