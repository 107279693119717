import { Form, Input, Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import './compare.scss';

import {
    LineChartOutlined,
    DownloadOutlined,
} from '@ant-design/icons'

class PortfolioConfirmModal extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        
    }

    layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    
    onFinish = (values) => {
        console.log(values)
    }

    onFinishFailed = () => {
        console.log('bad input')
    }

    handleClick = () => {
        this.props.deletePortfolio();        
    }

    render() {
        return (
            <>
                <Modal
                    className='details-modal'
                    title={`Manage Portfolio`}
                    visible='true'
                    onOk={() => {
                        if (this.handleClick()) {
                            this.props.close();
                        }
                    }}
                    onCancel={() => {
                        this.props.close();
                    }}
                    destroyOnClose={true}
                    okButtonProps={{
                        icon: <LineChartOutlined />,
                        type: 'normal',
                    }}
                    cancelButtonProps={{
                        //icon: <DownloadOutlined />,
                        //className: 'export-button',
                        //style: { float: 'left', display: (canExport(this.props)) ? 'block' : 'none' },
                        onClick: () => {
                            this.props.close();
                        }
                    }}
                    okText='Delete'>
                    <span>Do you want to delete the current portfolio?</span>
                </Modal>
        </>)
    }
}

const mapStateToProps = ({ currentPortfolio }) => {
    return {
        currentPortfolio
    }
}

export default connect(mapStateToProps, {
})(withRouter(PortfolioConfirmModal))