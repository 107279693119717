import { applyMiddleware, createStore } from 'redux'
import storage from 'redux-persist/es/storage'
import { composeWithDevTools } from 'redux-devtools-extension'
import { INITIAL_DASHBOARD_ENTITY_STATE } from './entity/entityConstants'
import { persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'

import reducer from './reducer'

// we could choose to just only persist auth...
// UX is way better with everything persisted
const persistConfig = {
    key: 'root',
    storage,
}

const INITIAL_STATE = { entities: INITIAL_DASHBOARD_ENTITY_STATE }

const persistedReducer = persistReducer(persistConfig, reducer)
const store = createStore(
    persistedReducer,
    INITIAL_STATE,
    composeWithDevTools(applyMiddleware(thunk))
)

const persistor = persistStore(store)

export { persistor, store }
