import React, { Component } from 'react'
import { Row } from 'antd'
import { Form, Input, Button } from 'antd'
import { connect } from 'react-redux'
import _ from 'lodash'
import AmplifyService from '../../AmplifyService'

class PasswordResetForm extends Component {
    state = {
        errorMessage: '',
        resetSuccess: false,
        verificationSent: true
    }

    handleConfirmPassword = (rule, value, callback) => {
        if (value && value !== this.passwordBox.input.value) {
            callback('Passwords are not matching.')
        }
        callback()
    }

    onFinish = async (values) => {
        try {
            let res = await this.props.handleResetPassword(values);
			// password reset successfully, show the succees message
			// and redirect to login form
            this.setState({errorMessage: '', resetSuccess: true})
            this.props.setResetSuccess();
        }
        catch (err) {
            this.setState({verificationSent: false});
            if (err.code === 'CodeMismatchException') {
                this.setState({errorMessage: "Please enter a valid verification code."});
            }
            else {
                this.setState({errorMessage: "Failed to reset password. Please try again later."});
            }
        }
    }

    onFinishFailed = (errorInfo) => {
        // console.log("Failed:", errorInfo);
    }

    render() {
        const actionText = 'Reset Password'
        const { registrationEmail, passwordVerification } = this.props
        

        return (
            <>
                <Row className='content_row' justify='center' align='middle'>
                    <h1>{actionText}</h1>
                    {this.state.verificationSent && <h3>A verification code has been sent to your registered email. Please enter the code and reset your password</h3>}
                </Row>
                <Row className='content_row' justify='center' align='middle'>
                    {this.state.errorMessage.length > 0 && <div>
                        <p style={{color: 'red'}}>{this.state.errorMessage}</p>
                        <Button type='primary' block onClick={async (e) => {
                            try {
                                let res = await AmplifyService.forgotPassword(registrationEmail);
                                this.setState({errorMessage: '', resetSuccess: false, verificationSent: true});
                            }
                            catch (err) {
                                this.setState({errorMessage: 'Failed to resend verification code. Please try again later.'});
                            }
                        }}>
                            Resend
                        </Button>
                    </div>}
                    {this.state.resetSuccess && <div style={{color: 'green'}}>
                        Password successfully reset. You will be redirected to the login page.
                    </div>}
                </Row>
                <Form
                    name='basic'
                    labelCol={{ span: 14, offset: 5 }}
                    wrapperCol={{ span: 14, offset: 5 }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete='off'
                    layout='vertical'
                    initialValues={{
                        username: registrationEmail,
                    }}>

                    <Form.Item
                        name="username"
                    >
                        <Input type="hidden"/>
                    </Form.Item>

                    <Form.Item
                        label='Verification Code'
                        name='code'
                        rules={[
                            {
                                required: true,
                                message: 'Please input your verification code!',
                            },
                        ]}>
                        <Input  />
                    </Form.Item>

                    <Form.Item label='New Password' required>
                        <Form.Item
                            name='password'
                            noStyle
                            rules={passwordVerification}>
                            <Input.Password 
                            ref={(node) => {
                                this.passwordBox = node
                            }} 
                            />
                        </Form.Item>
                    </Form.Item>

                    <Form.Item label='Confirm Password' required>
                        <Form.Item
                            name='password1'
                            noStyle
                            rules={
                                this.props.matchPasswordVerification
                            }>
                            <Input.Password />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary' block htmlType='submit'>
                            Confirm Password Reset
                        </Button>

                        <Button type='secondary' block style={{marginTop: 5}} onClick={(e) => { this.props.setResetPassword(false)}}>
                            Cancel
                        </Button>
                        
                    </Form.Item>
                </Form>
            </>
        )
    }
}

export default connect(null, {})(PasswordResetForm)

// leaving here in case we want to have single shot verification
// export const validatePassword = (password) => {
//     const re = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%?=*&]).{8,20})/
//     if (re.test(String(password))) return Promise.resolve()
//     return Promise.reject(
//         new Error(
//             'Password is not valid. Passwords must contain 1 upper case, 1 lower case, 1 number, 1 symbol and be between 6 and 98 characters long.'
//         )
//     )
// }
