import { SET_TOKEN, SET_USER, LOGOUT } from './authConstants'

import ApiService from '../apiService'
import { HOME_URL } from '../../shared/constants'

export const setToken = ({ authToken, refreshToken }) => {
    return {
        type: SET_TOKEN,
        authToken,
        refreshToken,
    }
}

export const logoutUser = () => {
    return {
        type: LOGOUT,
    }
}

export const setUser = (user) => {
    return {
        type: SET_USER,
        user,
    }
}

export const getMe = (token) => async (dispatch) => {
    try {
        const getMe = await ApiService.getMe(token)
        dispatch(setUser(getMe.data))
        return getMe.data
    } catch (error) {
        console.warn(error)
    }
}

export const logout = (token) => async (dispatch) => {
    try {
        // const logout = await ApiService.logout(token)
        dispatch(logoutUser())
        //dispatch(setAnalyzeSchool(null))
        // dispatch(setAllSchools(null))

        return HOME_URL
    } catch (error) {
        console.warn(error)
    }
}

export const refresh = (refreshTok) => async (dispatch) => {
    // try {
    // const refreshed = await ApiService.refreshToken(refreshTok)
    // const newToken = {
    //     authToken: refreshed.data.access_token,
    //     refreshToken: refreshTok,
    // }
    // dispatch(setToken(newToken))
    // return newToken
    return true
    // } catch (error) {
    //     console.warn(error)
    // }
}