import produce from 'immer'
import { SET_MODAL_STATE } from './UIConstants'

const INITIAL_STATE = {
    modals: {
        group: {
            visible: false,
        },
        users: {
            visible: false,
        },
    },
}

export default produce((draft = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_MODAL_STATE:
            let { modalKey, state } = action
            draft.modals[modalKey] = {
                ...draft.modals[modalKey],
                ...state,
            }
            break
        default:
            break
    }
    return draft
})
