import axios from 'axios'
import qs from 'qs'
import {
    BACKEND_URL,
    HOME_URL,
    LOGOUT_ENDPOINT,
    REFRESH_TOKEN_ENDPOINT,
} from '../shared/constants'
import { GROUPS_ENDPOINT } from './group/groupConstants'
import { store } from './persistentStore'
import {
    FINALIZE_USER_ENDPOINT,
    EMAIL_ENDPOINT,
    USERS_ENDPOINT,
    USER_GROUPS_ENDPOINT,
} from './user/userConstants'

const GET_METHOD = 'get'
const POST_METHOD = 'post'
const PATCH_METHOD = 'patch'
const DELETE_METHOD = 'delete'

axios.interceptors.response.use(
    (response) => {
        return response
    },
    (e) => {
        const status = !e ? null : !e.response ? null : e.response.status
        if (status && (status === 401 || status === 403)) {
            let {
                auth: { authToken },
            } = store.getState()
            if (authToken) {
                localStorage.clear()
                window.location = HOME_URL
            }
            return e
        } else {
            return e
        }
    }
)

// fix query params to allow multiple of the same value when passed as an array
axios.defaults.paramsSerializer = (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' })
}

class ApiService {
    constructor(baseUrl) {
        this.baseUrl = baseUrl
    }

    getCompareSchoolData = async (token, { school_id, cmo_id, obligated_group_id }) => {
        const url = school_id
            ? `${BACKEND_URL}/schools/${school_id}/compare_data`
            : (cmo_id ? `${BACKEND_URL}/cmos/${cmo_id}/compare_data`
            : `${BACKEND_URL}/obligated_groups/${obligated_group_id}/compare_data`)
        const getSchool = await axios({
            method: GET_METHOD,
            url: url,
            headers: { Authorization: `Bearer ${token}` },
        })
        return getSchool
    }

    getCompareEntityData = async (token, { school_id, cmo_id, obligated_group_id }) => {
        const url = school_id
            ? `${BACKEND_URL}/schools/${school_id}/compare_data`
            : (cmo_id ? `${BACKEND_URL}/cmos/${cmo_id}/compare_data`
            : `${BACKEND_URL}/obligated_groups/${obligated_group_id}/compare_data`)
        const getSchool = await axios({
            method: GET_METHOD,
            url: url,
            headers: { Authorization: `Bearer ${token}` },
        })
        return getSchool
    }

    postCompareEntityData = async (token, data) => {
        const excel = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/export_compare/?token=${token}`,
            data: data,
        })
        return excel
    }

    getMe = async (token) => {
        try {
            const getMe = await axios({
                method: GET_METHOD,
                url: `${BACKEND_URL}/me`,
                headers: { Authorization: `Bearer ${token}` },
            })
            return getMe
        } catch (e) {
            return e
        }
    }

    getDashboardEntities = async (token, params) => {
        const req = await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/dashboard_query`,
            headers: { Authorization: `Bearer ${token}` },
            params,
        })
        return req
    }

    getEntityQuery = async (token, params) => {
        const req = await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/entity_query`,
            headers: { Authorization: `Bearer ${token}` },
            params,
        })
        return req
    }

    getAnalyzeInfo = async (token, { school_id, cmo_id, obligated_group_id }) => {
        const url = school_id
            ? `${BACKEND_URL}/schools/${school_id}/analyze_data`
            : (cmo_id ? `${BACKEND_URL}/cmos/${cmo_id}/analyze_data` 
            : `${BACKEND_URL}/obligated_groups/${obligated_group_id}/analyze_data`)
        const getAnaylze = await axios({
            method: GET_METHOD,
            url: url,
            headers: { Authorization: `Bearer ${token}` },
        })
        return getAnaylze
    }

    compareSearch = async (token, params) => {
        const req = await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/compare_query`,
            headers: { Authorization: `Bearer ${token}` },
            params,
        })
        return req
    }

    upsertPortfolio = async (token, id, name, entities) => {
        const data = {name: name};
        if (id) {
            data['id'] = id;
        }
        if (entities && entities.length > 0) {
            data["entities"] = entities;
        } 
        const req = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/portfolios/`,
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            data: JSON.stringify(data)
        });
        return req
    }

    // get all user portfolios
    getPortfolios = async (token) => {
        const req = await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/portfolios/0`,
            headers: { Authorization: `Bearer ${token}` }
        })
        return req
    }

    getPortfolio = async (token, id) => {
        const req = await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/portfolios/${id}`,
            headers: { Authorization: `Bearer ${token}` }
        })
        return req
    }

    deletePortfolio = async (token, id) => {
        const req = await axios({
            method: DELETE_METHOD,
            url: `${BACKEND_URL}/portfolios/${id}`,
            headers: { Authorization: `Bearer ${token}` }
        })
        return req
    }

    addSchoolComment = async (token, comment, school_id) => {
        const url = `${BACKEND_URL}/schools/comments`
        const addComment = await axios({
            method: POST_METHOD,
            url: url,
            headers: { Authorization: `Bearer ${token}` },
            data: {
                school_id,
                comment,
            },
        })
        return addComment
    }

    editSchoolComment = async (token, comment, id) => {
        const url = `${BACKEND_URL}/schools/comments/${id}`
        const editComment = await axios({
            method: PATCH_METHOD,
            url,
            headers: { Authorization: `Bearer ${token}` },
            data: {
                comment,
            },
        })
        return editComment
    }

    deleteSchoolComment = async (token, id) => {
        const url = `${BACKEND_URL}/schools/comments/${id}`
        await axios({
            method: DELETE_METHOD,
            url: url,
            headers: { Authorization: `Bearer ${token}` },
        })
    }

    addCmoComment = async (token, comment, cmo_id) => {
        const url = `${BACKEND_URL}/cmos/comments`
        const addComment = await axios({
            method: POST_METHOD,
            url: url,
            headers: { Authorization: `Bearer ${token}` },
            data: {
                cmo_id,
                comment,
            },
        })
        return addComment
    }

    editCmoComment = async (token, comment, id) => {
        const url = `${BACKEND_URL}/cmos/comments/${id}`
        const editComment = await axios({
            method: PATCH_METHOD,
            url,
            headers: { Authorization: `Bearer ${token}` },
            data: {
                comment,
            },
        })
        return editComment
    }

    deleteCmoComment = async (token, id) => {
        const url = `${BACKEND_URL}/cmos/comments/${id}`
        await axios({
            method: DELETE_METHOD,
            url: url,
            headers: { Authorization: `Bearer ${token}` },
        })
    }

    getCmo = async (token, id) => {
        const url = `${BACKEND_URL}/cmos/${id}`
        const getCmo = await axios({
            method: GET_METHOD,
            url,
            headers: { Authorization: `Bearer ${token}` },
        })
        return getCmo
    }

    editCmo = async (token, payload, id) => {
        const url = `${BACKEND_URL}/cmos/${id}`

        delete payload.active
        delete payload.id

        const editCmo = await axios({
            method: PATCH_METHOD,
            url,
            headers: { Authorization: `Bearer ${token}` },
            data: payload,
        })
        return editCmo
    }

    addObligatedGroupComment = async (token, comment, obligated_group_id) => {
        const url = `${BACKEND_URL}/obligated_groups/comments`
        const addComment = await axios({
            method: POST_METHOD,
            url: url,
            headers: { Authorization: `Bearer ${token}` },
            data: {
                obligated_group_id,
                comment,
            },
        })
        return addComment
    }

    editObligatedGroupComment = async (token, comment, id) => {
        const url = `${BACKEND_URL}/obligated_groups/comments/${id}`
        const editComment = await axios({
            method: PATCH_METHOD,
            url,
            headers: { Authorization: `Bearer ${token}` },
            data: {
                comment,
            },
        })
        return editComment
    }

    deleteObligatedGroupComment = async (token, id) => {
        const url = `${BACKEND_URL}/obligated_groups/comments/${id}`
        await axios({
            method: DELETE_METHOD,
            url: url,
            headers: { Authorization: `Bearer ${token}` },
        })
    }

    getObligatedGroup = async (token, id) => {
        const url = `${BACKEND_URL}/obligated_groups/${id}`
        const result = await axios({
            method: GET_METHOD,
            url,
            headers: { Authorization: `Bearer ${token}` },
        })
        return result
    }

    editObligatedGroup = async (token, payload, id) => {
        const url = `${BACKEND_URL}/obligated_groups/${id}`

        delete payload.active
        delete payload.id

        const result = await axios({
            method: PATCH_METHOD,
            url,
            headers: { Authorization: `Bearer ${token}` },
            data: payload,
        })
        return result;
    }

    getSchool = async (token, id) => {
        const url = `${BACKEND_URL}/schools/${id}`
        const getSchool = await axios({
            method: GET_METHOD,
            url,
            headers: { Authorization: `Bearer ${token}` },
        })
        return getSchool
    }

    editSchool = async (token, payload, id) => {
        const url = `${BACKEND_URL}/schools/${id}`

        delete payload.active
        delete payload.id
        delete payload.agency_code
        delete payload.cmo_id
        delete payload.entity_type
        delete payload.cmo
        delete payload.obligated_group
        delete payload.obligated_group_id

        const editSchool = await axios({
            method: PATCH_METHOD,
            url,
            headers: { Authorization: `Bearer ${token}` },
            data: payload,
        })
        return editSchool
    }

    getCharter = async (token, id) => {
        const url = `${BACKEND_URL}/charters/${id}`
        const getCharter = await axios({
            method: GET_METHOD,
            url,
            headers: { Authorization: `Bearer ${token}` },
        })
        return getCharter
    }

    editCharter = async (token, payload, id) => {
        const url = `${BACKEND_URL}/charters/${id}`

        delete payload.active
        delete payload.school_id

        const editCharter = await axios({
            method: PATCH_METHOD,
            url,
            headers: { Authorization: `Bearer ${token}` },
            data: payload,
        })
        return editCharter
    }

    editEntityType = async (token, payload, id) => {
        const url = `${BACKEND_URL}/schools/${id}/entity_type`

        const editEntityType = await axios({
            method: POST_METHOD,
            url,
            headers: { Authorization: `Bearer ${token}` },
            data: payload,
        })
        return editEntityType
    }

    getCmoQuery = async (token, searchValue) => {
        const url = `${BACKEND_URL}/cmo_query`

        const getCmoQuery = await axios({
            method: GET_METHOD,
            url,
            headers: { Authorization: `Bearer ${token}` },
            params: { name: searchValue },
        })
        return getCmoQuery
    }

    logout = async (token) => {
        const logout = await axios({
            method: GET_METHOD,
            url: `${LOGOUT_ENDPOINT}`,
            headers: { Authorization: `Bearer ${token}` },
        })
        return logout
    }

    refreshToken = async (refreshTok) => {
        const refreshToken = await axios({
            method: POST_METHOD,
            url: `${REFRESH_TOKEN_ENDPOINT}`,
            headers: { Authorization: `Bearer ${refreshTok}` },
        })
        return refreshToken
    }

    getUsers = async (refreshTok) => {
        const refreshToken = await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}${USERS_ENDPOINT}`,
            headers: { Authorization: `Bearer ${refreshTok}` },
        })
        return refreshToken
    }

    postUser = async (token, data) => {
        const req = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}${USERS_ENDPOINT}`,
            headers: { Authorization: `Bearer ${token}` },
            data,
        })
        return req
    }

    deleteUser = async (token, userId) => {
        const req = await axios({
            method: DELETE_METHOD,
            url: `${BACKEND_URL}${USERS_ENDPOINT}${userId}`,
            headers: { Authorization: `Bearer ${token}` },
        })
        return req
    }
    
    updateUser = async (token, data, userId) => {
        console.log(data)
        const refreshToken = await axios({
            method: PATCH_METHOD,
            url: `${BACKEND_URL}${USERS_ENDPOINT}${userId}`,
            headers: { Authorization: `Bearer ${token}` },
            data,
        })
        return refreshToken
    }

    finishRegistration = async (token, data) => {
        const refreshToken = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}${FINALIZE_USER_ENDPOINT}`,
            headers: { Authorization: `Bearer ${token}` },
            data,
        })
        return refreshToken
    }

    verifyRegistration = async (email, token) => {
        const verify = await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}${FINALIZE_USER_ENDPOINT}`,
            params: { email: email, token: token },
        })
        return verify
    }

    sendUserEmails = async (data, token) => {
        const verify = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}${EMAIL_ENDPOINT}`,
            headers: { Authorization: `Bearer ${token}` },
            data
        })
        return verify
    }

    getGroups = async (refreshTok) => {
        const refreshToken = await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}${GROUPS_ENDPOINT}`,
            headers: { Authorization: `Bearer ${refreshTok}` },
        })
        return refreshToken
    }

    postGroup = async (refreshTok, data) => {
        const refreshToken = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}${GROUPS_ENDPOINT}`,
            headers: { Authorization: `Bearer ${refreshTok}` },
            data,
        })
        return refreshToken
    }

    updateGroup = async (refreshTok, data, groupId) => {
        const refreshToken = await axios({
            method: PATCH_METHOD,
            url: `${BACKEND_URL}${GROUPS_ENDPOINT}${groupId}`,
            headers: { Authorization: `Bearer ${refreshTok}` },
            data,
        })
        return refreshToken
    }

    addUserToGroup = async (tok, userId, groupId) => {
        const req = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}${USER_GROUPS_ENDPOINT}${userId}/${groupId}`,
            headers: { Authorization: `Bearer ${tok}` },
            data: {},
        })
        return req
    }

    removeUserFromGroup = async (tok, userId, groupId) => {
        const req = await axios({
            method: DELETE_METHOD,
            url: `${BACKEND_URL}${USER_GROUPS_ENDPOINT}${userId}/${groupId}`,
            headers: { Authorization: `Bearer ${tok}` },
        })
        return req
    }
}

export default new ApiService()
