import React, { Component } from 'react'
import {
    Modal,
    Form,
    Input,
    InputNumber,
    Select,
    notification,
    DatePicker,
    AutoComplete,
} from 'antd'
import { ERROR_COLOR } from '../../shared/constants'
import _ from 'lodash'
import {
    CloseOutlined,
    SaveOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons'
import apiService from '../../features/apiService'
import moment from 'moment'

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
}

const FORM_ITEM_CLASS = 'dashboard-modal-form-item '

class AdminModal extends Component {
    state = {
        cmoData: null,
        obligatedGroupData: null,
        schoolData: null,
        charterData: null,
        entityTypeData: null,
        cmoCandidates: [],
    }

    showAdmin() {
        const { isAdmin } = this.props
        return isAdmin()
    }

    getCmoData() {
        return this.state.cmoData
    }

    getObligatedGroupData() {
        return this.state.obligatedGroupData
    }

    getSchoolData() {
        return this.state.schoolData
    }

    getCharterData() {
        return this.state.charterData
    }

    createConfirmPromise(token, onClose) {
        return new Promise((resolve, reject) => {
            this.setState({ confirmLoading: true })
            if (!_.isEmpty(this.getCmoData())) {
                const data = this.getCmoData()
                apiService.editCmo(token, data, data.id).then((resp) => {
                    if (!resp || !resp.data) {
                        notification.error({
                            duration: 0,
                            placement: 'topRight',
                            message: 'Unable to update Entity',
                            description: 'Please contact system adminstrator.',
                            style: {
                                backgroundColor: ERROR_COLOR,
                            },
                        })
                        reject()
                    }
                    onClose()
                    this.setState({
                        cmoData: null,
                        obligatedGroupData: null,
                        schoolData: null,
                        charterData: null,
                        confirmLoading: false,
                    })
                    resolve()
                })
            } 
            else if (!_.isEmpty(this.getObligatedGroupData())) {
                const data = this.getObligatedGroupData()
                apiService.editObligatedGroup(token, data, data.id).then((resp) => {
                    if (!resp || !resp.data) {
                        notification.error({
                            duration: 0,
                            placement: 'topRight',
                            message: 'Unable to update Entity',
                            description: 'Please contact system adminstrator.',
                            style: {
                                backgroundColor: ERROR_COLOR,
                            },
                        })
                        reject()
                    }
                    onClose()
                    this.setState({
                        cmoData: null,
                        obligatedGroupData: null,
                        schoolData: null,
                        charterData: null,
                        confirmLoading: false,
                    })
                    resolve()
                })
            } 
            else {
                const schoolData = this.getSchoolData()
                const charterData = this.getCharterData()
                const { id, entity_type, cmo_id, obligated_group_id } = schoolData

                Promise.all([
                    apiService.editSchool(token, schoolData, id),
                    apiService.editCharter(token, charterData, id),
                    apiService.editEntityType(
                        token,
                        {
                            entity_type,
                            cmo_id,
                            obligated_group_id
                        },
                        id
                    ),
                ]).then((resps) => {
                    if (
                        !resps ||
                        !resps[0] ||
                        !resps[0].data ||
                        !resps[1] ||
                        !resps[1].data ||
                        !resps[2] ||
                        !resps[2].data
                    ) {
                        notification.error({
                            duration: 0,
                            placement: 'topRight',
                            message: 'Unable to update Entity',
                            description: 'Please contact system adminstrator.',
                            style: {
                                backgroundColor: ERROR_COLOR,
                            },
                        })
                        reject()
                    }
                    onClose()
                    this.setState({
                        cmoData: null,
                        obligatedGroupData: null,
                        schoolData: null,
                        charterData: null,
                        confirmLoading: false,
                    })
                    resolve()
                })
            }
        }).catch((e) => console.log(e))
    }

    render() {
        if (!this.showAdmin()) {
            return <></>
        }

        const {
            cmoData,
            obligatedGroupData,
            schoolData,
            charterData,
            confirmLoading,
            cmoSearch,
            cmoCandidates,
        } = this.state
        const {
            token,
            onClose,
            getStateOptions,
            loadCmo,
            loadObligatedGroup,
            loadSchool,
            loadCharter,
        } = this.props
        const { Option } = AutoComplete

        const cmo = loadCmo ? loadCmo() : null
        const obligatedGroup = loadObligatedGroup ? loadObligatedGroup() : null
        const school = loadSchool ? loadSchool() : null
        const charter = loadCharter ? loadCharter() : null

        if (!cmoData && cmo) {
            this.setState({ cmoData: cmo })
        }
        if (!obligatedGroupData && obligatedGroup) {
            this.setState({ obligatedGroupData: obligatedGroup })
        }
        if (!schoolData && school) {
            this.setState({ schoolData: school })
        }
        if (!charterData && charter) {
            this.setState({ charterData: charter })
        }

        const confirmationPromise = () =>
            this.createConfirmPromise(token, onClose)

        return (
            <>
                <Modal
                    className='details-modal'
                    title={`Edit Entity: ${
                        !_.isEmpty(cmoData)
                            ? `${cmoData.name} - ${cmoData.id}`
                            : !_.isEmpty(schoolData)
                            ? `${schoolData.name} - ${schoolData.id}`
                            : (!_.isEmpty(obligatedGroupData) 
                            ? `${obligatedGroupData.name} - ${obligatedGroupData.id}`
                            : null)
                    }`}
                    visible={
                        !_.isEmpty(cmoData) ||
                        !_.isEmpty(obligatedGroupData) ||
                        !_.isEmpty(schoolData) ||
                        !_.isEmpty(charterData)
                    }
                    confirmLoading={confirmLoading}
                    onOk={() => {

                        onClose()
                        this.setState({
                            cmoData: null,
                            obligatedGroupData: null,
                            schoolData: null,
                            charterData: null,
                        })

                        /*Modal.confirm({
                            title: 'Confirm',
                            icon: <ExclamationCircleOutlined />,
                            content:
                                'Are you sure you want to persist these changes to the Database?',
                            okText: 'Yes',
                            cancelText: 'No',
                            onOk() {
                                return confirmationPromise()
                            },
                        }) */
                    }}
                    onCancel={() => {
                        onClose()
                        this.setState({
                            cmoData: null,
                            obligatedGroupData: null,
                            schoolData: null,
                            charterData: null,
                        })
                    }}
                    destroyOnClose={true}
                    cancelText='Cancel'
                    okButtonProps={{
                        icon: <SaveOutlined />,
                        type: 'normal',
                        style: {display: 'none'}
                    }}
                    cancelButtonProps={{
                        icon: <CloseOutlined />,
                        style: { display: 'none', float: 'left' },
                    }}
                    okText='Ok'>
                    <Form
                        {...layout}
                        name='basic'
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}>

                        { !_.isEmpty(this.getObligatedGroupData()) ? 
                            <>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label={'Entity Name'}
                                    name='name'
                                    initialValue={
                                        _.isEmpty(obligatedGroupData) ? null : obligatedGroupData.name
                                    }>
                                    <Input
                                        onChange={(e) => {
                                            this.setState({
                                                obligatedGroupData: {
                                                    ...obligatedGroupData,
                                                    name: e.target.value,
                                                },
                                            })
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label={'State'}
                                    name='state'>
                                    <Select
                                        defaultValue={
                                            _.isEmpty(obligatedGroupData)
                                                ? null
                                                : obligatedGroupData.state
                                        }
                                        onChange={(v) => {
                                            this.setState({
                                                obligatedGroupData: {
                                                    ...obligatedGroupData,
                                                    state: v,
                                                },
                                            })
                                        }}>
                                        {getStateOptions()}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label='Debt Issuer?'
                                    name='debt_issuer'
                                    style={{ color: 'red' }}
                                    initialValue={
                                        _.isEmpty(obligatedGroupData)
                                            ? null
                                            : obligatedGroupData.debt_issuer
                                            ? 'Yes'
                                            : 'No'
                                    }>
                                    <Select
                                        onChange={(v) => {
                                            this.setState({
                                                obligatedGroupData: {
                                                    ...obligatedGroupData,
                                                    debt_issuer: v,
                                                },
                                            })
                                        }}>
                                        <Select.Option value={true}>
                                            Yes
                                        </Select.Option>
                                        <Select.Option value={false}>
                                            No
                                        </Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item className={FORM_ITEM_CLASS} />
                                <Form.Item className={FORM_ITEM_CLASS} />
                                <Form.Item className={FORM_ITEM_CLASS} />
                            </> 
                            : ( 
                            !_.isEmpty(this.getCmoData()) ? (
                            // CMO
                            <>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label={'Entity Name'}
                                    name='name'
                                    initialValue={
                                        _.isEmpty(cmoData) ? null : cmoData.name
                                    }>
                                    <Input
                                        onChange={(e) => {
                                            this.setState({
                                                cmoData: {
                                                    ...cmoData,
                                                    name: e.target.value,
                                                },
                                            })
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label={'State'}
                                    name='state'>
                                    <Select
                                        defaultValue={
                                            _.isEmpty(cmoData)
                                                ? null
                                                : cmoData.state
                                        }
                                        onChange={(v) => {
                                            this.setState({
                                                cmoData: {
                                                    ...cmoData,
                                                    state: v,
                                                },
                                            })
                                        }}>
                                        {getStateOptions()}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label={'Year Founded'}
                                    name='year_founded'
                                    initialValue={
                                        _.isEmpty(cmoData)
                                            ? null
                                            : cmoData.year_founded
                                    }>
                                    <InputNumber
                                        onChange={(v) => {
                                            this.setState({
                                                cmoData: {
                                                    ...cmoData,
                                                    year_founded: v.toString(),
                                                },
                                            })
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label={'Website'}
                                    name='website'
                                    initialValue={
                                        _.isEmpty(cmoData)
                                            ? null
                                            : cmoData.website
                                    }>
                                    <Input
                                        onChange={(e) => {
                                            this.setState({
                                                cmoData: {
                                                    ...cmoData,
                                                    website: e.target.value,
                                                },
                                            })
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label='Debt Issuer?'
                                    name='debt_issuer'
                                    style={{ color: 'red' }}
                                    initialValue={
                                        _.isEmpty(cmoData)
                                            ? null
                                            : cmoData.debt_issuer
                                            ? 'Yes'
                                            : 'No'
                                    }>
                                    <Select
                                        onChange={(v) => {
                                            this.setState({
                                                cmoData: {
                                                    ...cmoData,
                                                    debt_issuer: v,
                                                },
                                            })
                                        }}>
                                        <Select.Option value={true}>
                                            Yes
                                        </Select.Option>
                                        <Select.Option value={false}>
                                            No
                                        </Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item className={FORM_ITEM_CLASS} />
                                <Form.Item className={FORM_ITEM_CLASS} />
                                <Form.Item className={FORM_ITEM_CLASS} />
                            </>
                        ) : (
                            //School
                            <>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label={'School Name'}
                                    name='name'
                                    initialValue={
                                        _.isEmpty(schoolData)
                                            ? null
                                            : schoolData.name
                                    }>
                                    <Input
                                        onChange={(e) => {
                                            this.setState({
                                                schoolData: {
                                                    ...schoolData,
                                                    name: e.target.value,
                                                },
                                            })
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label={'Street Address'}
                                    name={'street_addr'}
                                    initialValue={
                                        _.isEmpty(schoolData)
                                            ? null
                                            : schoolData.street_addr
                                    }>
                                    <Input
                                        onChange={(e) => {
                                            this.setState({
                                                schoolData: {
                                                    ...schoolData,
                                                    street_addr: e.target.value,
                                                },
                                            })
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label={'State'}
                                    name={'state'}>
                                    <Select
                                        defaultValue={
                                            _.isEmpty(schoolData)
                                                ? null
                                                : schoolData.state
                                        }
                                        onChange={(v) => {
                                            this.setState({
                                                schoolData: {
                                                    ...schoolData,
                                                    state: v,
                                                },
                                            })
                                        }}>
                                        {getStateOptions()}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label={'City'}
                                    name={'city'}
                                    initialValue={
                                        _.isEmpty(schoolData)
                                            ? null
                                            : schoolData.city
                                    }>
                                    <Input
                                        onChange={(e) => {
                                            this.setState({
                                                schoolData: {
                                                    ...schoolData,
                                                    city: e.target.value,
                                                },
                                            })
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label={'County'}
                                    name={'county'}
                                    initialValue={
                                        _.isEmpty(schoolData)
                                            ? null
                                            : schoolData.county
                                    }>
                                    <Input
                                        onChange={(e) => {
                                            this.setState({
                                                schoolData: {
                                                    ...schoolData,
                                                    county: e.target.value,
                                                },
                                            })
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label={'Zip'}
                                    name='zip'
                                    initialValue={
                                        _.isEmpty(schoolData)
                                            ? null
                                            : schoolData.zip
                                    }>
                                    <InputNumber
                                        onChange={(v) => {
                                            this.setState({
                                                schoolData: {
                                                    ...schoolData,
                                                    zip: v,
                                                },
                                            })
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label={'Grade Span'}
                                    name={'grade_span'}
                                    initialValue={
                                        _.isEmpty(schoolData)
                                            ? null
                                            : schoolData.grade_span
                                    }>
                                    <Input
                                        onChange={(e) => {
                                            this.setState({
                                                schoolData: {
                                                    ...schoolData,
                                                    grade_span: e.target.value,
                                                },
                                            })
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label={'Website'}
                                    name={'website'}
                                    initialValue={
                                        _.isEmpty(schoolData)
                                            ? null
                                            : schoolData.website
                                    }>
                                    <Input
                                        onChange={(e) => {
                                            this.setState({
                                                schoolData: {
                                                    ...schoolData,
                                                    website: e.target.value,
                                                },
                                            })
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label='Separate Financials'
                                    name='separate_financials'
                                    style={{ color: 'red' }}
                                    initialValue={
                                        _.isEmpty(schoolData)
                                            ? null
                                            : schoolData.separate_financial
                                            ? 'Yes'
                                            : 'No'
                                    }>
                                    <Select
                                        onChange={(v) => {
                                            this.setState({
                                                schoolData: {
                                                    ...schoolData,
                                                    separate_financial: v,
                                                },
                                            })
                                        }}>
                                        <Select.Option value={true}>
                                            Yes
                                        </Select.Option>
                                        <Select.Option value={false}>
                                            No
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                                {/* Charter*/}
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label='Year Opened'
                                    name='year_opened'
                                    initialValue={
                                        (_.isEmpty(charterData) || charterData.year_opened == null)
                                            ? ''
                                            : charterData.year_opened
                                    }>
                                    <InputNumber
                                        onChange={(v) => {
                                            this.setState({
                                                charterData: {
                                                    ...charterData,
                                                    year_opened: v.toString(),
                                                },
                                            })
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label='Authorizer'
                                    name='authorizer'
                                    initialValue={
                                        _.isEmpty(charterData)
                                            ? null
                                            : charterData.authorizer
                                    }>
                                    <Input
                                        onChange={(e) => {
                                            this.setState({
                                                charterData: {
                                                    ...charterData,
                                                    authorizer: e.target.value,
                                                },
                                            })
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label='Debt Issuer'
                                    name='debt_issuer'
                                    style={{ color: 'red' }}
                                    initialValue={
                                        _.isEmpty(charterData)
                                            ? null
                                            : charterData.debt_issuer
                                            ? 'Yes'
                                            : 'No'
                                    }>
                                    <Select
                                        onChange={(v) => {
                                            this.setState({
                                                charterData: {
                                                    ...charterData,
                                                    debt_issuer: v,
                                                },
                                            })
                                        }}>
                                        <Select.Option value={true}>
                                            Yes
                                        </Select.Option>
                                        <Select.Option value={false}>
                                            No
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label='Can Expire'
                                    name='can_expire'
                                    style={{ color: 'red' }}
                                    initialValue={
                                        _.isEmpty(charterData)
                                            ? null
                                            : charterData.can_expire
                                            ? 'Yes'
                                            : 'No'
                                    }>
                                    <Select
                                        onChange={(v) => {
                                            this.setState({
                                                charterData: {
                                                    ...charterData,
                                                    can_expire: v,
                                                },
                                            })
                                        }}>
                                        <Select.Option value={true}>
                                            Yes
                                        </Select.Option>
                                        <Select.Option value={false}>
                                            No
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label='Charter Expiration'
                                    name='charter_expiration'>
                                    <DatePicker
                                        defaultValue={
                                            (_.isEmpty(charterData) || charterData.charter_expiration == null)
                                                ? null
                                                : moment(
                                                      charterData.charter_expiration
                                                  )
                                        }
                                        onChange={(date, dateStr) => {
                                            this.setState({
                                                charterData: {
                                                    ...charterData,
                                                    charter_expiration: _.isEmpty(dateStr)? null : dateStr,
                                                },
                                            })
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label='Entity Type'
                                    name='entity_type_edit'
                                    initialValue={
                                        _.isEmpty(schoolData)
                                            ? null
                                            : schoolData.entity_type
                                    }>
                                    <Select
                                        onChange={(v) => {
                                            this.setState({
                                                schoolData: {
                                                    ...schoolData,
                                                    entity_type: v,
                                                    cmo:
                                                        v === 'CMO Member'
                                                            ? schoolData.cmo
                                                            : null,
                                                },
                                                cmoSearch: null,
                                            })
                                        }}>
                                        <Select.Option value={'N/A'}>
                                            N/A
                                        </Select.Option>
                                        <Select.Option value={'Freestanding'}>
                                            Freestanding
                                        </Select.Option>
                                        <Select.Option value={'CMO Member'}>
                                            CMO Member
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    className={FORM_ITEM_CLASS}
                                    label='CMO'
                                    name='cmo'
                                    initialValue={
                                        _.isEmpty(schoolData)
                                            ? null
                                            : schoolData.cmo
                                            ? schoolData.cmo.name
                                            : null
                                    }>
                                    {!schoolData ||
                                    schoolData.entity_type !== 'CMO Member' ? (
                                        <Input disabled={true} />
                                    ) : (
                                        <AutoComplete
                                            style={{
                                                width: '99%',
                                                marginLeft: 5,
                                            }}
                                            value={
                                                !cmoSearch
                                                    ? null
                                                    : schoolData &&
                                                      schoolData.cmo
                                                    ? schoolData.cmo.name
                                                    : null
                                            }
                                            onChange={(searchValue) => {
                                                apiService
                                                    .getCmoQuery(
                                                        token,
                                                        searchValue
                                                    )
                                                    .then((resp) => {
                                                        if (
                                                            resp &&
                                                            resp.data &&
                                                            resp.data.items
                                                        ) {
                                                            this.setState({
                                                                cmoCandidates:
                                                                    resp.data
                                                                        .items,
                                                                cmoSearch: searchValue,
                                                            })
                                                        }
                                                    })
                                            }}
                                            onSelect={(val) => {
                                                const target = _.filter(
                                                    cmoCandidates,
                                                    (entity) =>
                                                        entity.id === parseInt(val.split(' ')[0])
                                                )[0]
                                                this.setState({
                                                    schoolData: {
                                                        ...schoolData,
                                                        cmo: {
                                                            ...schoolData.cmo,
                                                            id: target.id,
                                                            name: target.name,
                                                        },
                                                        cmo_id: target.id,
                                                    },
                                                })
                                            }}
                                            filterOption={(
                                                inputValue,
                                                option
                                            ) => {
                                                return (
                                                    option.children
                                                        .toUpperCase()
                                                        .indexOf(
                                                            inputValue.toUpperCase()
                                                        ) !== -1
                                                )
                                            }}>
                                            {cmoCandidates ? (
                                                cmoCandidates.map((c) => (
                                                    <Option
                                                        key={c.id}
                                                        value={`${c.id} ${c.name} ${c.state}`}>
                                                        {`${c.name} (${c.state})`}    
                                                    </Option>
                                                ))
                                            ) : (
                                                <> </>
                                            )}
                                        </AutoComplete>
                                    )}
                                </Form.Item>
                            </>
                        ))}
                    </Form>
                </Modal>
            </>
        )
    }
}

export default AdminModal
