import { Form, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { canExport } from '../Authorization';

import {
    ANALYZE_URL,
    MISSING_METADATA_STR,
    BACKEND_URL
} from '../../shared/constants'

import {
    LineChartOutlined,
    DownloadOutlined,
} from '@ant-design/icons'

import {
    setAnalyzeEntityAndLoadData,
    setAnalyzeSchoolEntityAndLoadData

} from '../../features/entity/entityActions'

import _ from 'lodash'

import GAService from '../../GAService';
import BLPInstance from '../../BloombergService';

class EntityModal extends Component {
    constructor(props) {
        super(props);
        const { entityModalData } = this.props;

        this.state = { 
            entityModalData: entityModalData
        }
    }

    layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    onFinish = (values) => {
        console.log(values)
    }

    onFinishFailed = () => {
        console.log('bad input')
    }

    isCMO() {
        const { entityModalData } = this.state
        return entityModalData && entityModalData.charter_type === 'CMO'
    }

    isObligatedGroup() {
        const { entityModalData } = this.state
        return entityModalData && entityModalData.charter_type === 'Obligated Group'
    }

    buildExportUrl(entityModalData) {
        if (entityModalData && entityModalData.school_id) {
            return `${BACKEND_URL}/schools/${entityModalData.school_id}/export?token=${this.props.token}&format=xlsx`
        } else if (entityModalData && entityModalData.cmo_id && this.isCMO()) {
            return `${BACKEND_URL}/cmos/${entityModalData.cmo_id}/export?token=${this.props.token}&format=xlsx`
        } else if (entityModalData && entityModalData.obligated_group_id && this.isObligatedGroup()) {
            return `${BACKEND_URL}/obligated_groups/${entityModalData.obligated_group_id}/export?token=${this.props.token}&format=xlsx`
        }else {
            console.error(
                'unknown type of export for entityModal: ' + entityModalData
            )
            return null
        }
    }

    buildLocationStr(entityModalData) {
        if (_.isEmpty(entityModalData)) {
            return MISSING_METADATA_STR
        }
        if (this.isCMO() || this.isObligatedGroup()) {
            return entityModalData.state
        }
        if (entityModalData.street_addr) {
            return `${entityModalData.street_addr}, ${entityModalData.city}, ${entityModalData.state}`
        } else {
            return `${entityModalData.city}, ${entityModalData.state}`
        }
    }

    getCharterExpirationForSnapshot(entityModalData) {
        return (
            <Form.Item
                className='dashboard-modal-form-item '
                label='Charter Expiration'
                name='charter_expiration'
                initialValue={
                    _.isEmpty(entityModalData)
                        ? null
                        : !entityModalData.charter_expire
                        ? MISSING_METADATA_STR
                        : entityModalData.charter_expire
                }>
                <Input disabled={true} />
            </Form.Item>
        )
    }

    getAuthoirzerForSnapshot(entityModalData) {
        return (
            <Form.Item
                className='dashboard-modal-form-item '
                label='Authorizer'
                name='authorizer'
                initialValue={
                    _.isEmpty(entityModalData)
                        ? null
                        : !entityModalData.authorizer
                        ? MISSING_METADATA_STR
                        : entityModalData.authorizer
                }>
                <Input disabled={true} />
            </Form.Item>
        )
    }

    getNumSchoolsForSnapshot(entityModalData) {
        return (
            <Form.Item
                className='dashboard-modal-form-item'
                label='Charter Schools'
                name='number_charter_schools'
                initialValue={
                    _.isEmpty(entityModalData)
                        ? null
                        : !entityModalData.num_schools
                        ? MISSING_METADATA_STR
                        : entityModalData.num_schools
                }>
                <Input disabled={true} />
            </Form.Item>
        )
    }

    getNumStatesForSnapshot(entityModalData) {
        return (
            <Form.Item
                className='dashboard-modal-form-item'
                label='States'
                name='num_states'
                initialValue={
                    _.isEmpty(entityModalData)
                        ? null
                        : !entityModalData.num_states
                        ? MISSING_METADATA_STR
                        : entityModalData.num_states
                }>
                <Input disabled={true} />
            </Form.Item>
        )
    }

    render() {
        const { setEntityModalData } = this.props;

    return (
        <>
            <Modal
                className='details-modal'
                title={`View Details: ${
                    _.isEmpty(this.state.entityModalData) ? null : this.state.entityModalData.name
                }`}
                visible={!_.isEmpty(this.state.entityModalData)}
                onOk={() => {
                    const {
                        setAnalyzeEntityAndLoadData,
                        setAnalyzeSchoolEntityAndLoadData,
                        target,
                        history,
                    } = this.props
                    
                    if (target !== 'new') {
                        GAService.analyze('Details', this.isCMO() ? 'cmo' : (this.isObligatedGroup() ? 'obligated group' : 'school'));
                        setAnalyzeEntityAndLoadData(this.state.entityModalData);
                        history.push(ANALYZE_URL);
                    }
                    else {
                        // opened from school list of the CMO analyze page
                        GAService.analyze('Details', 'school');
                        setAnalyzeSchoolEntityAndLoadData(this.state.entityModalData);
                        if (!BLPInstance.isBloomberg(this.props)) {
                            window.open(ANALYZE_URL + '?sid=' + this.state.entityModalData.school_id, '_blank');
                        }
                        else {
                            history.push(ANALYZE_URL + '?sid=' + this.state.entityModalData.school_id, '_blank');
                        }
                    }
                }}
                onCancel={() => {
                    setEntityModalData(null);
                }}
                destroyOnClose={true}
                cancelText='Export'
                okButtonProps={{
                    icon: <LineChartOutlined />,
                    type: 'normal',
                }}
                cancelButtonProps={{
                    icon: <DownloadOutlined />,
                    className: 'export-button',
                    style: { float: 'left', display: (canExport(this.props)) ? 'block' : 'none' },
                    onClick: () => {
                        GAService.exportReport('Details', this.isCMO() ? 'cmo' : (this.isObligatedGroup() ? 'obligated group' : 'school'));
                        window.open(
                            this.buildExportUrl(this.state.entityModalData),
                            '_blank'
                        );
                    }
                }}
                okText='Analyze'>
                <Form
                    {...this.layout}
                    name='basic'
                    initialValues={{ remember: true }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}>
                    <Form.Item
                        className='dashboard-modal-form-item '
                        label={this.isCMO() ? 'Entity Name' : 'School Name'}
                        name='name'
                        initialValue={
                            _.isEmpty(this.state.entityModalData)
                                ? null
                                : this.state.entityModalData.name
                        }>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        className='dashboard-modal-form-item '
                        label={this.isCMO() || this.isObligatedGroup() ? 'HQState' : 'Location'}
                        name='location'
                        initialValue={this.buildLocationStr(
                            this.state.entityModalData
                        )}>
                        <Input disabled={true} />
                    </Form.Item>
                    {this.isCMO() || this.isObligatedGroup()
                        ? this.getNumSchoolsForSnapshot(this.state.entityModalData)
                        : this.getCharterExpirationForSnapshot(
                                this.state.entityModalData
                            )}

                    {this.isCMO() || this.isObligatedGroup()
                        ? this.getNumStatesForSnapshot(this.state.entityModalData)
                        : this.getAuthoirzerForSnapshot(this.state.entityModalData)}
                    <Form.Item
                        className='dashboard-modal-form-item '
                        label={
                            this.isCMO() || this.isObligatedGroup()
                                ? 'Total Enrollment'
                                : 'Current Enrollment'
                        }
                        name='enrollment'
                        initialValue={
                            _.isEmpty(this.state.entityModalData)
                                ? null
                                : this.state.entityModalData.enrollment
                                ? this.state.entityModalData.enrollment
                                : MISSING_METADATA_STR
                        }>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        className='dashboard-modal-form-item '
                        label='Entity Type'
                        name='entity_type'
                        initialValue={
                            _.isEmpty(this.state.entityModalData) ||
                            !this.state.entityModalData.charter_type
                                ? MISSING_METADATA_STR
                                : this.state.entityModalData.charter_type
                        }>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        className='dashboard-modal-form-item '
                        label='Total Debt ($000s)'
                        name='total_debt'
                        initialValue={
                            _.isEmpty(this.state.entityModalData)
                                ? null
                                : this.state.entityModalData.total_debt
                                ? `${this.state.entityModalData.total_debt.toLocaleString()}`
                                : MISSING_METADATA_STR
                        }>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        className='dashboard-modal-form-item '
                        label='Public Debt Issuer?'
                        name='public_debt_issuer'
                        style={{ color: 'red' }}
                        initialValue={
                            _.isEmpty(this.state.entityModalData)
                                ? null
                                : this.state.entityModalData.debt_issuer
                                ? 'Yes'
                                : 'No'
                        }>
                        <Input disabled={true} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
    }
}

export default withRouter(EntityModal)