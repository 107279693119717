import React, { Component } from 'react'
import { Row, Col, Table, Tooltip, Modal, Form, Input } from 'antd'
import {
    ANALYZE_URL,
    MISSING_METADATA_STR,
    DEFAULT_TABLE_RENDER,
    BACKEND_URL
} from '../../shared/constants'

import { InfoCircleFilled } from '@ant-design/icons'

import {
    LineChartOutlined,
    DownloadOutlined,
    TableOutlined,
    SearchOutlined,
    EditOutlined,
} from '@ant-design/icons'

import {
    SubtitleEntryHalf,
    AnalyzeHeader,
    FinancialHighlights,
    AnalyzeComments,
    AnalyzeTitle,
    getWebsiteLink,
} from './AnalyzeCommon'

import {
    setAnalyzeEntityAndLoadData,
    setAnalyzeSchoolEntityAndLoadData
} from '../../features/entity/entityActions'

import { connect } from 'react-redux'

import { withRouter } from 'react-router'

import apiService from '../../features/apiService'
import _ from 'lodash'
import EntityModal from '../../shared/components/EntityModal'
import GAService from '../../GAService'

function AnalyzeGroupSubtitle(props) {
    const { data } = props
    const { entityID } = props
    const { token } = props
    const { user } = props
    const { context } = props
    const { type } = props;

    const addCommentCall = (type === 'CMO') ? apiService.addCmoComment : apiService.addObligatedGroupComment;
    const deleteCommentCall = (type === 'CMO') ? apiService.deleteCmoComment : apiService.deleteObligatedGroupComment;
    const editCommentCall =  (type === 'CMO') ? apiService.editCmoComment : apiService.editObligatedGroupComment;

    return (
        <>
            <Row
                style={{
                    height: 350,
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#eff6de',
                }}>
                <Col
                    span={7}
                    style={{
                        color: '#1c467c',
                        paddingTop: 40,
                    }}>
                    <SubtitleEntryHalf
                        value={
                            data.school_list
                                ? data.school_list.length
                                : MISSING_METADATA_STR
                        }
                        name={'NUMBER OF SCHOOLS'}
                    />
                     <SubtitleEntryHalf
                        value={data.info.num_states}
                        name={'NUMBER OF STATES'}
                    />
                </Col>
                <Col
                    span={1}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        justifyItems: 'center',
                        paddingBottom: 20,
                    }}>
                    <div
                        style={{
                            borderRight: '2px solid #88b725',
                            height: 280,
                        }}
                    />
                </Col>
                <Col
                    span={7}
                    style={{
                        color: '#1c467c',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: 80,
                    }}>
                    <div
                        style={{
                            height: 300,
                        }}>
                        <div
                            style={{
                                paddingTop: 30,
                                justifyContent: 'center',
                                alignContent: 'space-between',
                                fontSize: 28,
                                fontWeight: 'bold',
                                alignItems: 'flex-center',
                                display: 'flex',
                                textAlign: 'center',
                            }}>
                            {'Website'}
                        </div>
                        <div
                            style={{
                                justifyContent: 'center',
                                alignContent: 'space-between',
                                fontSize: 15,
                                alignItems: 'flex-center',
                                display: 'flex',
                                textAlign: 'center',
                            }}>
                            {getWebsiteLink(data.info.website, context)}
                        </div>
                    </div>
                </Col>
                <Col
                    span={1}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        justifyItems: 'center',
                        paddingBottom: 20,
                    }}>
                    <div
                        style={{
                            borderLeft: '2px solid #88b725',
                            height: 280,
                        }}
                    />
                </Col>
                <Col
                    span={7}
                    style={{
                        color: '#1c467c',
                        paddingTop: 40,
                    }}>

                     <SubtitleEntryHalf
                        value={data.enrollment && data.enrollment.length > 0 && data.enrollment[0].value ? parseInt(data.enrollment[0].value).toLocaleString("en-US") : 'N/A'}
                        name={'ENROLLMENT'}
                    />

                    <AnalyzeComments
                        comments={data.comments}
                        name={data.info.name}
                        token={token}
                        entityID={entityID}
                        apiAddComment={addCommentCall} 
                        apiRemoveComment={deleteCommentCall} 
                        apiEditComment={editCommentCall}
                        user={user}
                    />

                </Col>
            </Row>
        </>
    )
}

function GroupSchoolTableList(props) {
    const { data } = props
    const { setTargetSchool } = props
    const type = data.cmo_id ? 'CMO' : 'Obligated Group';

    data.forEach((e) => {
        e.authorizer = e.authorizer === 0 ? MISSING_METADATA_STR : e.authorizer
        e.year_opened =
            e.year_opened === 0 ? MISSING_METADATA_STR : e.year_opened
    })
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (<span style={{cursor: 'pointer'}}>{text}</span>)
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            width: 20,
            render: DEFAULT_TABLE_RENDER,
        },
        {
            title: 'Year Open',
            dataIndex: 'year_opened',
            key: 'year_opened',
            width: 20,
            render: DEFAULT_TABLE_RENDER,
        },
        {
            title: 'Enrollment',
            dataIndex: 'enrollment',
            key: 'enrollment',
            width: 20,
            render: DEFAULT_TABLE_RENDER,
        },
        {
            title: 'Authorizer',
            dataIndex: 'authorizer',
            key: 'authorizer',
            render: DEFAULT_TABLE_RENDER,
        },
        {
            title: 'Charter Expiration',
            dataIndex: 'charter_expire',
            key: 'charter_expire',
            render: DEFAULT_TABLE_RENDER,
        },
    ];

    return (
        <>
            <div class="ant-row"><h1>School List</h1>&nbsp;<Tooltip title="Click individual school in the list to view details"><InfoCircleFilled style={{marginTop: 8}} /></Tooltip></div>
            <Table
                pagination={{ pageSize: 6 }}
                style={{ paddingBottom: '10' }}
                className='analyze-table'
                dataSource={data}
                columns={columns}
                rowKey='school_id'
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            GAService.openModal(`${type} Analyze School List`, "Details", "school");
                            setTargetSchool(record);
                        }, // click row
                    }
                }}
            />
            
        </>
    )
}

function GroupSchoolInfo(props) {
    const { schoolList } = props
    const { setTargetSchool } = props
    const { type } = props;

    return (
        <Row gutter={16}>
            <Col span={16}>
                <GroupSchoolTableList data={schoolList} setTargetSchool={setTargetSchool} type={type} />
            </Col>
            <Col span={8}>
                <div />
                {/* <CmoEnrollmentGraph data={enrollment} /> */}
            </Col>
        </Row>
    )
}

class AnalyzeGroup extends Component {
    constructor(props) {
        super(props);
        this.state = { target_school: null}
        this.setTargetSchool = this.setTargetSchool.bind(this);
    }

    setTargetSchool(school) {
        this.setState({target_school: school})
    }

    render() {
        const { data } = this.props
        const { entities } = this.props
        const { entityID } = this.props
        const { token } = this.props
        const { user } = this.props
        const { type } = this.props;

        return (
            <>
                <AnalyzeTitle
                    name={data.info.name}
                    state={data.info.state}
                    year={data.info.year_founded}
                    yearText={(type == 'CMO') ? `${type} Year Founded` : ''}
                />
                <AnalyzeGroupSubtitle
                    data={data}
                    entityID={entityID}
                    token={token}
                    user={user}
                    context={entities.context}
                    type={type}
                />
                <div style={{ paddingTop: 25 }} />
                <AnalyzeHeader name={`${type}/NETWORK SCHOOLS`} />
                <GroupSchoolInfo
                    schoolList={data.school_list}
                    enrollment={data.enrollment}
                    setTargetSchool={this.setTargetSchool}
                    type={type}
                />
                <div style={{ paddingTop: 25 }} />
                <FinancialHighlights data={data} context={entities.context} />
                {this.state.target_school && <EntityModal {...this.props} entityModalData={this.state.target_school} setEntityModalData={this.setTargetSchool} target="new" />}
            </>
        )
    }
}


const mapStateToProps = ({ auth: { authToken }, entities, ...rest }) => {
    return {
        authToken,
        entities
    }
}

export default connect(mapStateToProps, {
    setAnalyzeEntityAndLoadData,
    setAnalyzeSchoolEntityAndLoadData    
})(withRouter(AnalyzeGroup))
