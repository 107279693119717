import React, { Component } from 'react'
import { Row, Col, Table } from 'antd'
import {
    SubtitleEntryHalf,
    SubtitleEntryThird,
    AreaGraph,
    SIP_GRAPH_BLUE,
    SIP_GRAPH_GREEN,
    AnalyzeHeader,
    FinancialHighlights,
    AnalyzeComments,
    AnalyzeContacts,
    AnalyzeTitle,
    // UnauditedFinancials,
    getWebsiteLink,
} from './AnalyzeCommon'
import {
    DEFAULT_TABLE_RENDER,
    MISSING_METADATA_STR,
} from '../../shared/constants'
import _ from 'lodash'
import apiService from '../../features/apiService'

function AnalyzeSchoolSubtitle(props) {
    const { data } = props
    const { entityID } = props
    const { token } = props
    const { user } = props
    const { context } = props

    return (
        <>
            <Row
                style={{
                    height: 350,
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#eff6de',
                }}>
                <Col
                    span={7}
                    style={{
                        color: '#1c467c',
                        paddingTop: 40,
                    }}>
                    <SubtitleEntryHalf
                        value={data.info.authorizer}
                        name={'AUTHORIZER'}
                    />
                    <SubtitleEntryHalf
                        value={
                            data.info.charter_expire
                                ? data.info.charter_expire
                                : MISSING_METADATA_STR
                        }
                        name={'CHARTER EXPIRATION'}
                    />
                </Col>
                <Col
                    span={1}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        justifyItems: 'center',
                        paddingBottom: 20,
                    }}>
                    <div
                        style={{
                            borderRight: '2px solid #88b725',
                            height: 280,
                        }}
                    />
                </Col>
                <Col
                    span={7}
                    style={{
                        color: '#1c467c',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: 10,
                    }}>
                    <SubtitleEntryThird
                        value={data.info.grade_span}
                        name={'GRADE SPAN'}
                    />
                    <AnalyzeContacts
                        contacts={data.contacts}
                        name={data.info.name}
                        entityID={entityID}
                    />
                    <SubtitleEntryThird
                        value={'Website'}
                        name={getWebsiteLink(data.info.website, context)}
                    />
                </Col>
                <Col
                    span={1}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        justifyItems: 'center',
                        paddingBottom: 20,
                    }}>
                    <div
                        style={{
                            borderLeft: '2px solid #88b725',
                            height: 280,
                        }}
                    />
                </Col>
                <Col
                    span={7}
                    style={{
                        color: '#1c467c',
                        paddingTop: 40,
                    }}>
                    <SubtitleEntryHalf
                        value={data.info.network_name}
                        name={'CMO/NETWORK NAME'}
                    />
                    <AnalyzeComments
                        comments={data.comments}
                        name={data.info.name}
                        token={token}
                        entityID={entityID}
                        apiAddComment={apiService.addSchoolComment}
                        apiRemoveComment={apiService.deleteSchoolComment}
                        apiEditComment={apiService.editSchoolComment}
                        user={user}
                    />
                </Col>
            </Row>
        </>
    )
}

function StudentEnrollmentGraphs(props) {
    const { data } = props
    return (
        <Row gutter={16}>
            <Col span={8}>
                <AreaGraph
                    data={data.avg_student_num}
                    xField={'year'}
                    yField={'value'}
                    title={'Average Enrollment (Total)'}
                    height={350}
                    color={SIP_GRAPH_GREEN}
                />
            </Col>
            <Col span={8}>
                <AreaGraph
                    data={data.attendance_pct}
                    xField={'year'}
                    yField={'value'}
                    title={'Average Attendance (%)'}
                    yAxisFormatter={(v) => `${v} %`}
                    height={350}
                    color={SIP_GRAPH_BLUE}
                />
            </Col>
            <Col span={8}>
                <AreaGraph
                    data={data.economically_disadvantaged}
                    xField={'year'}
                    yField={'value'}
                    title={'Economically Disadvantaged (%)'}
                    yAxisFormatter={(v) => `${v} %`}
                    height={350}
                    color={SIP_GRAPH_GREEN}
                />
            </Col>
        </Row>
    )
}

function AcademicPerformance(props) {
    const { data } = props
    const dataOrdered = _.sortBy(data, 'year').reverse()
    const columns = [
        {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
            render: DEFAULT_TABLE_RENDER,
        },
        {
            title: 'Academic Grade',
            dataIndex: 'spg_grade',
            key: 'spg_grade',
            render: DEFAULT_TABLE_RENDER,
        },
        {
            title: 'Academic Growth Score',
            dataIndex: 'eg_score',
            key: 'eg_score',
            render: DEFAULT_TABLE_RENDER,
        },
        {
            title: 'Academic Growth Status',
            dataIndex: 'eg_status',
            key: 'eg_status',
            render: DEFAULT_TABLE_RENDER,
        },
    ]
    return (
        <Table
            pagination={{ pageSize: 5 }}
            style={{ paddingBottom: '10' }}
            className='analyze-table'
            dataSource={dataOrdered}
            columns={columns}
            rowKey='year'
        />
    )
}

function HighschoolGraphs(props) {
    const { data } = props

    return data.info.is_highschool ? (
        <>
            <AreaGraph
                data={data.graduation_rate}
                xField={'year'}
                yField={'value'}
                title={'Graduation Rate (%)'}
                yAxisFormatter={(v) => `${v} %`}
                height={165}
                color={SIP_GRAPH_GREEN}
            />
            <AreaGraph
                data={data.dropout_rate}
                xField={'year'}
                yField={'value'}
                title={'Dropout Rate (%)'}
                yAxisFormatter={(v) => `${v} %`}
                height={165}
                color={SIP_GRAPH_BLUE}
            />
            :
        </>
    ) : (
        <>
            <div style={{ paddingTop: 12, paddingLeft: 10, paddingRight: 10 }}>
                <h2>{'Graduation Rate (%): N/A'}</h2>
            </div>
            <div style={{ paddingTop: 150, paddingLeft: 10, paddingRight: 10 }}>
                <h2>{'Dropout Rate (%): N/A'}</h2>
            </div>
        </>
    )
}

function AcademicPerformanceGraphs(props) {
    const { data } = props
    return (
        <Row gutter={16}>
            <Col span={8}>
                <h1>Academic Performance</h1>
                <AcademicPerformance data={data.academic_performance} />
            </Col>
            <Col span={8}>
                <AreaGraph
                    data={data.teacher_qualifications}
                    xField={'year'}
                    yField={'value'}
                    title={'Beginning Teachers (%)'}
                    yAxisFormatter={(v) => `${v} %`}
                    height={350}
                    color={SIP_GRAPH_BLUE}
                />
            </Col>
            <Col span={8}>
                <HighschoolGraphs data={data} />
            </Col>
        </Row>
    )
}

export default class AnalyzeSchool extends Component {
    render() {
        const { data } = this.props
        const { entities } = this.props
        const { token } = this.props
        const { entityID } = this.props
        const { user } = this.props
        
        return (
            <>
                <AnalyzeTitle
                    name={data.info.name}
                    state={data.info.state}
                    year={data.info.year_opened}
                    yearText={'Year Opened'}
                />
                <AnalyzeSchoolSubtitle
                    data={data}
                    token={token}
                    entityID={entityID}
                    user={user}
                    context={entities.context}
                />
                <div style={{ paddingTop: 25 }} />
                <AnalyzeHeader name={'STUDENT ENROLLMENT & STATISTICS'} />
                <StudentEnrollmentGraphs data={data} />
                <div style={{ paddingTop: 25 }} />
                <AnalyzeHeader name={'ACADEMIC PERFORMANCE'} />
                <AcademicPerformanceGraphs data={data} />
                <div style={{ paddingTop: 25 }} />
                <FinancialHighlights data={data} context={entities.context} />
                <div style={{ paddingTop: 25 }} />
                {/* <UnauditedFinancials
                    entityID={entityID}
                    name={data.info.name}
                    financials={data.unaudited_financials}
                /> */}
            </>
        )
    }
}
