import produce from 'immer'
import { ADD_GROUP, SET_GROUPS, UPDATE_GROUP } from './groupConstants'

export default produce((draft = {}, action) => {
    switch (action.type) {
        case SET_GROUPS:
            draft = action.groups
            break
        case ADD_GROUP:
            let { groupData } = action
            draft[action.groupData.id] = groupData
            break
        case UPDATE_GROUP:
            draft[action.groupId] = {
                ...draft[action.groupId],
                ...action.groupData,
            }
            break
        default:
            break
    }
    return draft
})
