import { SET_MODAL_STATE } from './UIConstants'

// Dashboard
export const setModalState = (modalKey, state) => {
    return {
        type: SET_MODAL_STATE,
        modalKey,
        state,
    }
}
