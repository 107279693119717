import { Form, Input, Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import './compare.scss';

import {
    LineChartOutlined,
    DownloadOutlined,
} from '@ant-design/icons'

class PortfolioModal extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        this.state = {
            name: props.name ? props.name : '',
        }
    }

    componentDidMount() {
        
    }

    layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    
    onNameChange = (event) => {
        this.setState({
            name: event.target.value
        });
    }

    onFinish = (values) => {
        console.log(values)
    }

    onFinishFailed = () => {
        console.log('bad input')
    }

    handleClick = () => {
        if (this.props.portfolioId) {
            // rename portfolio
        }
        else {
            // create a new portfolio
            this.props.createPortfolio(this.state.name);
        }
    }

    render() {
        return (
            <>
                <Modal
                    className='details-modal'
                    title={`Manage Portfolio`}
                    visible='true'
                    onOk={() => {
                        if (this.handleClick()) {
                            this.props.close();
                        }
                    }}
                    onCancel={() => {
                        this.props.close();
                    }}
                    destroyOnClose={true}
                    okButtonProps={{
                        icon: <LineChartOutlined />,
                        type: 'normal',
                    }}
                    cancelButtonProps={{
                        //icon: <DownloadOutlined />,
                        //className: 'export-button',
                        //style: { float: 'left', display: (canExport(this.props)) ? 'block' : 'none' },
                        onClick: () => {
                            this.props.close();
                        }
                    }}
                    okText={this.props.name ? 'Save' : 'Create'}>
                    <Form
                        ref={this.formRef}
                        {...this.layout}
                        name='basic'
                        initialValues={{ remember: true }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}>

                        <div className="ant-row ant-form-item dashboard-modal-form-item ">
                            <div className="ant-col ant-col-8 ant-form-item-label">
                                <label>Name</label>   
                            </div>
                            <div
                                className='dashboard-modal-form-item '
                                label='Name'
                                name='name'
                                initialvalue={this.state.name}
                                >
                                <Input type="text" onChange={this.onNameChange} value={this.state.name} />
                            </div>
                        </div>
                    </Form>
                </Modal>
        </>)
    }
}

const mapStateToProps = ({ currentPortfolio }) => {
    return {
        currentPortfolio
    }
}

export default connect(mapStateToProps, {
})(withRouter(PortfolioModal))