import React, { Component } from 'react'
import { Home, Inactive, Analyze, Compare } from '../../screens'
import { Switch, Route, Redirect } from 'react-router-dom'
import { withRouter } from 'react-router'
import {
    HOME_URL,
    INACTIVE_LOGIN_URL,
    ANALYZE_URL,
    COMPARE_URL,
    ADMIN_URL,
} from '../constants'
import AdminScreen from '../../screens/admin'

const HomeRedirect = ({ location }) => <Redirect to={HOME_URL} />

class ContentArea extends Component {
    render() {
        return (
            <div
                style={{ height: '100%', marginTop: '25px' }}
                className={'contentarea'}
                onMouseMove={this.handleMouseMove}>
                <Switch>
                    <Route
                        render={(props) => (
                            <Inactive {...props} token={this.props.token} />
                        )}
                        path={INACTIVE_LOGIN_URL}
                    />
                    <Route
                        render={(props) => (
                            <Compare {...props} token={this.props.token} />
                        )}
                        path={COMPARE_URL}
                    />
                    <Route
                        render={(props) => <AdminScreen />}
                        path={ADMIN_URL}
                    />
                    <Route
                        render={(props) => (
                            <Analyze
                                {...props}
                                token={this.props.token}
                                user={this.props.user}
                            />
                        )}
                        path={ANALYZE_URL}
                    />
                    <Route
                        render={(props) => (
                            <Home
                                {...props}
                                token={this.props.token}
                                user={this.props.user}
                                allSchools={this.props.entities.all.items}
                                total={this.props.entities.all.total}
                            />
                        )}
                        path={HOME_URL}
                    />
                    <Route component={HomeRedirect} />
                </Switch>
            </div>
        )
    }
}

export default withRouter(ContentArea)
